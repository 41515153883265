import {useContext, useEffect, useState} from "react";
import {OrganisationContext, UserContext} from "../../../App";
import {useNavigate} from "react-router-dom";
import {ERRORTEXTCOLOR, OrganisationAddress, PlacementListing, camelCaseToNormal, capitalise, capitaliseWords, useLoadListings} from "placementt-core";
import {where} from "firebase/firestore";
import FilterList from "../../../Components/FilterList";
import {Box, Button, Chip, Grid, List, ListItem, ListItemButton, ListItemSecondaryAction, ListItemText, Snackbar, Stack, Typography} from "@mui/material";
import IconButtonPop from "../../../Components/IconButtonPop";
import {ContentCopy, Delete, Edit} from "@mui/icons-material";
import {Popup} from "../../../Components/Popup";
import DeletePopup from "../../../Components/DeletePopup";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import {FilterObject} from "../../../Components/FilterTable";
import Alert from "../../../Components/Alert";

type ListingsPageParams = {
    type: "all"|"published"|"draft",
    userType?: "provider"|"student"|"institute",
    providerId?: string,
    addressId?: string,
    filters?: {[key:string]: unknown},
    hideFilters?: boolean
}

export default function PlacementListingsList({type, filters, userType, addressId, hideFilters}: ListingsPageParams) {
    const user = useContext(UserContext);
    const navigate = useNavigate();
    const org = useContext(OrganisationContext);
    const addresses = org?.addresses as {[key: string]: OrganisationAddress};
    const [viewPlacementPopup, setViewPlacementPopup] = useState<{id: string, listing: PlacementListing}>();
    const [deleteListing, setDeleteListing] = useState<{key: string, listingTitle: string}>();
    const [snackbarMessage, setSnackbarMessage] = useState<string>();

    const fUserType: "provider"|"student"|"institute" = user.product === "providers" ?
        (userType || "provider") : (user.product === "institutes" && user.userType === "Staff") ? "institute" : "student";


    const constraints = [];

    const firebaseQuery = new FirebaseQuery();

    if (addressId) constraints.push(where("addressId", "==", addressId));
    if (fUserType !== "provider" || type === "published") constraints.push(where("status", "==", "listed"));
    if (fUserType !== "provider" || type === "draft") constraints.push(where("status", "==", "draft"));

    const filterNames: FilterObject = {
        status: {
            label: "Status",
            type: "dropdown",
            values: {
                draft: "draft",
                listed: "Listed",
                unlisted: "Unlisted",
            },
            value: filters?.status,
        }};


    useEffect(() => {
        console.log("FILTERS", filters);
        // const newConstraints = Object.entries(filterNames).filter(([, item]) => item.value !== undefined).map(([name, item]) => where(name, "==", parseInt(item.value as string) || item.value));

        const newNewConstraints = Object.entries(filters || {}).filter(([key, value]) => key !== "id" && value !== undefined).map(([key, value]) => {
            if (filterNames[key]) {
                return where(key, "==", parseInt(filterNames[key].value as string) || filterNames[key].value);
            } else {
                return where(key, "==", ["true", "false"].includes(value as string) ? value === "true" : parseInt(value as string) || value);
            }
        });

        changeQueryConstraints(newNewConstraints);
    }, [filters]);

    const {listings, onScrollBottom, loading, changeQueryConstraints} = useLoadListings(user, constraints);

    const onListingClicked = (listing: PlacementListing, id: string) => {
        if (fUserType === "provider") {
            navigate(`/providers/${listing.status === "listed" ? "viewListing" : "addListing"}/${id}${listing.status === "listed" ? "/overview" : ""}`);
            return;
        }
        setViewPlacementPopup({id: id, listing: listing});
    };

    console.log("LISTINGS", listings);


    return (
        <Box height={"500px"} overflow={"hidden"}>
            {!org.details.insurance && type !== "draft" && Object.keys(listings).length > 0 && <Alert severity="error" action={<Button color="inherit" onClick={() => navigate("/providers/home#insurance")}>Upload</Button>}>Listings cannot be made public until you upload your employers liability insurance</Alert>}
            <FilterList filters={hideFilters ? undefined : filterNames} urlRef={type} title={<Typography variant="h5">{capitalise(type)} listings</Typography>} card={false} data={Object.fromEntries(listings || [])} onScrollBottom={onScrollBottom} loadMoreIcon={loading}>
                {listings.map(([key, listing]) => {
                    const address = addresses?.[listing.addressId || ""];
                    return (<ListItemButton id={key} key={key} divider onClick={() => onListingClicked(listing, key)}>
                        <Stack direction={"row"} justifyContent={"space-between"} width={"100%"}>
                            <ListItemText primary={<Typography><strong style={{fontSize: "1.1em"}}>{listing.title || <span style={{opacity: 0.7}}>No title</span>}</strong><span style={{opacity: 0.75}}> - {address ? `${address?.["address-line1"]} | ${address?.postal_code.toUpperCase()} | ${capitaliseWords(camelCaseToNormal(address?.country))}` : <i style={{color: ERRORTEXTCOLOR}}><strong>Missing address</strong></i>}</span></Typography>}
                                secondary={
                                    <Stack pt={2} spacing={1}>
                                        <Typography>{listing.questions?.description}</Typography>
                                        {type !== "draft" && <Grid container>
                                            <Grid item xs={12} md={6}>
                                                <List>
                                                    <ListItem sx={{background: "#00000010", borderRadius: "20px", marginBottom: "10px", padding: 0, paddingLeft: 1.5}}>
                                                        <ListItemText primary="Applicants"/>
                                                        <ListItemSecondaryAction>
                                                            {listing.applicants}
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                    <ListItem sx={{background: "#00000010", borderRadius: "20px", marginBottom: "10px", padding: 0, paddingLeft: 1.5}}>
                                                        <ListItemText primary="Scheduled placements"/>
                                                        <ListItemSecondaryAction>
                                                            {listing.scheduled}
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                    <ListItem sx={{background: "#00000010", borderRadius: "20px", marginBottom: "10px", padding: 0, paddingLeft: 1.5}}>
                                                        <ListItemText primary="Active placements"/>
                                                        <ListItemSecondaryAction>
                                                            {listing.active}
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                </List>
                                            </Grid>
                                        </Grid>}
                                    </Stack>}/>
                            <Stack spacing={1}>
                                <Stack direction={"row"}>
                                    <Chip color={listing.status === "listed" ? "primary" : undefined} variant={listing.status === "listed" ? "filled" : undefined} label={capitalise(listing.status)}/>
                                </Stack>
                                <Stack direction={"row"} spacing={0} sx={{justifyContent: "flex-end"}}>
                                    <IconButtonPop responsive={false} title="Edit" onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        navigate(`/providers/addListing/${key}`);
                                    }}><Edit/></IconButtonPop>
                                    <IconButtonPop responsive={false} title="Copy listing" onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        firebaseQuery.add(["placementListings"], {...listing, title: listing.title + " [copy]", status: "draft"}).then(() => setSnackbarMessage("Item copied to drafts"));
                                    }}><ContentCopy/></IconButtonPop>
                                    <IconButtonPop responsive={false} title="Delete" onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setDeleteListing({key: key, listingTitle: listing.title});
                                    }}><Delete/></IconButtonPop>
                                </Stack>
                            </Stack>
                        </Stack>
                    </ListItemButton>);
                })}
            </FilterList>
            <Snackbar
                open={Boolean(snackbarMessage)}
                autoHideDuration={4000}
                onClose={() => setSnackbarMessage(undefined)}
                message={snackbarMessage}
            />
            {fUserType !== "provider" &&
                <Popup title={"View placement"} open={Boolean(viewPlacementPopup)} onClose={() => setViewPlacementPopup(undefined)}>
                    {<Button onClick={() => navigate(`/${user.product}/apply/${viewPlacementPopup?.id}`)}>Apply now</Button>}
                </Popup>}
            {fUserType === "provider" && <DeletePopup title="listing" onDelete={async () => await firebaseQuery.delete(["placementListings", deleteListing?.key || ""]).then(() => setDeleteListing(undefined))} itemName={deleteListing?.listingTitle} open={Boolean(deleteListing)} onClose={() => setDeleteListing(undefined)}/>}
        </Box>
    );
}
