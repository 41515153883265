import Page from "../../Components/Page";
import InfoPageHeader from "../../Components/Public/InfoPageHeader";
import Team from "../../Components/Public/Team";


export default function AboutUs() {
    return (
        <Page
            metaTitle="About Us | Placementt"
            metaDesc="Learn more about Placementt, our mission, vision, and the dedicated team working to revolutionize work experience management for all."
        >
            <InfoPageHeader
                title="About Us - Placementt"
                subtext="Learn more about Placementt, our mission, vision, and the dedicated team working to revolutionize work experience management for all."
                background= "#FFC107"
                scaleBackground
            />
            <Team />
        </Page>
    );
}
