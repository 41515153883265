import {Box, Button, Divider, Grid, InputAdornment, Link, Stack, Table, TableBody, TableCell, TableRow, ToggleButton, ToggleButtonGroup, Typography} from "@mui/material";
import {INSTITUTE_COLOUR} from "placementt-core";
import {ChangeEvent, useState} from "react";
import Card from "./Card";
import InputGroup from "./FormComponents/InputGroup";
import {InfoTableCell} from "../Util/styledComponents";
import {formatPrice} from "../Util/util";
import {Mail} from "@mui/icons-material";

const costObject = {
    school: {
        platformFee: 90,
        studentCost: 1.5,
        placementCost: 1.5,
    },
    mat: {
        platformFee: 250,
        studentCost: 2.5,
        placementCost: 2.5,
    },
    university: {
        platformFee: 250,
        studentCost: 5,
        placementCost: 5,
    },
};

export default function InstitutePriceCalculator() {
    const [orgSize, setOrgSize] = useState<"school"|"mat"|"university">("school");
    const [numAddresses, setNumAddresses] = useState<number>(1);
    const [numStudents, setNumStudents] = useState<number>();
    const [placementsPerStudent, setPlacementsPerStudent] = useState<number>(1);

    const studentTotalPrice = numStudents ? costObject[orgSize].studentCost*numStudents : "---";
    const placementTotalPrice = (numStudents !== undefined && placementsPerStudent !== undefined) ? (placementsPerStudent * numStudents * costObject[orgSize].placementCost) : "---";

    const totalPrice = (typeof studentTotalPrice === "number" && typeof placementTotalPrice === "number") ? costObject[orgSize].platformFee + studentTotalPrice + placementTotalPrice : "---";

    return (
        <Stack width={"100%"}>
            <Typography variant="h5" color={INSTITUTE_COLOUR}>Transparent Pricing Customised to Your Needs</Typography>
            <Typography>We are transparent about our pricing. We'll only charge you for usage, and tailor specific plans to you, ensuring you get the most out of Placementt. Use the cost estimator below to understand how we price our service. Get in touch to discuss what is included, and how we can tailor our platform to your needs.</Typography>
            <Grid container>
                <Card grid xs={12} md={6} sx={{height: 1}} title={"Estimate Your Pricing"} headerSx={{color: INSTITUTE_COLOUR}}>
                    <Stack height={"100%"} spacing={3}>
                        <Typography>We tailor Placementt to suit your needs, whether you are a secondary school, multi-academy trust or university. Fill in the fields below to estimate your pricing.</Typography>
                        <Divider/>
                        <Typography variant="h6" color={INSTITUTE_COLOUR}>More About You</Typography>
                        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                            <Typography fontSize={"1.1em"}>I am a...</Typography>
                            <ToggleButtonGroup color="institutes" exclusive value={orgSize} onChange={(_, n) => n ? setOrgSize(n) : null}>
                                <ToggleButton value={"school"}>Secondary school</ToggleButton>
                                <ToggleButton value={"mat"}>Multi-academy trust</ToggleButton>
                                <ToggleButton value={"university"}>University</ToggleButton>
                            </ToggleButtonGroup>
                        </Stack>
                        {orgSize === "mat" && <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                            <Typography fontSize={"1.1em"}>Number of schools</Typography>
                            <InputGroup sx={{width: "150px"}} InputProps={{
                                endAdornment: <InputAdornment position="end">school{numAddresses === 1 ? "" : "s"}</InputAdornment>,
                            }} type={"number"} value={numAddresses} min={"0"} onChange={(e:ChangeEvent<HTMLInputElement>) => setNumAddresses(parseInt(e.target.value))}/>
                        </Stack>}
                        <Stack pt={"3.5px"} direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                            <Stack spacing={0}>
                                <Typography fontSize={"1.1em"}>Number of students</Typography>
                                <Typography sx={{opacity: 0.7}} fontSize={14}>How many students do you expect to attend placements?</Typography>
                            </Stack>                            <InputGroup sx={{width: "150px"}} InputProps={{
                                endAdornment: <InputAdornment position="end">student{numStudents === 1 ? "" : "s"}</InputAdornment>,
                            }} type={"number"} value={numStudents} min={"0"} onChange={(e:ChangeEvent<HTMLInputElement>) => (parseInt(e.target.value) >=0) ? setNumStudents(parseInt(e.target.value)) : null}/>
                        </Stack>
                        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                            <Stack spacing={0}>
                                <Typography fontSize={"1.1em"}>Placements per student</Typography>
                                <Typography sx={{opacity: 0.7}} fontSize={14}>How many placements will each student complete?</Typography>
                            </Stack>
                            <InputGroup sx={{width: "150px"}} InputProps={{
                                endAdornment: <InputAdornment position="end">placement{placementsPerStudent === 1 ? "" : "s"}</InputAdornment>,
                            }} type={"number"} value={placementsPerStudent} min={"0"} onChange={(e:ChangeEvent<HTMLInputElement>) => (parseInt(e.target.value) >=0) ? setPlacementsPerStudent(parseInt(e.target.value)) : undefined}/>
                        </Stack>
                    </Stack>

                </Card>
                <Grid item xs={12} md={6}>
                    <Box borderRadius={"10px"} border={"1px solid lightgrey"} overflow={"hidden"}>
                        <Stack pb={4} pt={4} alignItems={"center"} spacing={0} sx={{background: INSTITUTE_COLOUR}}>
                            <Typography variant="h2" color={"white !important"} fontWeight={"bold"}><Typography variant="h5" sx={{display: "inline"}}>£</Typography>{typeof totalPrice === "number" && orgSize !== "university" ? formatPrice(totalPrice * 100, true) : "£---"}</Typography>
                            <Typography color={"white !important"}>Estimated yearly cost</Typography>
                        </Stack>
                        <Box height={"30px"} sx={{background: `linear-gradient(${INSTITUTE_COLOUR}, white)`}}/>
                        {orgSize !== "university" ? <Stack p={2} spacing={1}>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <InfoTableCell>Platform fee</InfoTableCell>
                                        <InfoTableCell sx={{textAlign: "end", opacity: 0.7}}>£{costObject[orgSize].platformFee} / year</InfoTableCell>
                                    </TableRow>
                                    <TableRow sx={{position: "absolute"}}>
                                        <InfoTableCell colSpan={2}>
                                            <Typography mt={-3.5} sx={{opacity: 0.7}} fontSize={"0.75rem"}>Access to Placementt for unlimited staff.</Typography>
                                        </InfoTableCell>
                                    </TableRow>
                                    <TableRow><TableCell colSpan={2}/></TableRow>
                                    <TableRow>
                                        <InfoTableCell>Price / student</InfoTableCell>
                                        <InfoTableCell sx={{textAlign: "end", opacity: 0.7}}>£{costObject[orgSize].studentCost} x {numStudents || "---"} = {typeof studentTotalPrice === "number" ? formatPrice(studentTotalPrice * 100) : "£---"}</InfoTableCell>
                                    </TableRow>
                                    <TableRow sx={{position: "absolute"}}>
                                        <InfoTableCell colSpan={2}>
                                            <Typography mt={-3.5} sx={{opacity: 0.7}} fontSize={"0.75rem"}>Price for creating and managing student accounts.</Typography>
                                        </InfoTableCell>
                                    </TableRow>
                                    <TableRow><TableCell colSpan={2}/></TableRow>
                                    <TableRow sx={{marginBotton: 0}}>
                                        <InfoTableCell>Price / placement</InfoTableCell>
                                        <InfoTableCell sx={{textAlign: "end", opacity: 0.7}}>£{costObject[orgSize].placementCost} x {numStudents || "---"} x {placementsPerStudent} = {typeof placementTotalPrice === "number" ? formatPrice(placementTotalPrice * 100) : "£---"}</InfoTableCell>
                                    </TableRow>
                                    <TableRow sx={{position: "absolute"}}>
                                        <InfoTableCell colSpan={2}>
                                            <Typography mt={-3.5} sx={{opacity: 0.7}} fontSize={"0.75rem"}>Not every student may create a placement, so this figure may be lower.</Typography>
                                        </InfoTableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Stack> : <Typography p={2} fontSize={"18px"} textAlign={"center"} sx={{opacity: 0.8}}>We are currently building Placementt for universities. Get in touch if you are interested in supporting your university by clicking the button below.</Typography>}
                        <Stack alignItems={"center"} p={3}>
                            <Button color="institutes" sx={{fontSize: "1.5em", padding: "0 16px"}} startIcon={<Mail fontSize="large"/>} onClick={() => window.open("mailto:tom@placementt.co.uk")}>Get in touch</Button>
                            <Typography color={INSTITUTE_COLOUR}>Or email Tom at <a style={{textDecoration: "underline", color: INSTITUTE_COLOUR}} href={"mailto:tom@placementt.co.uk"}>tom@placementt.co.uk</a></Typography>
                        </Stack>
                    </Box>
                </Grid>
            </Grid>
            <Link textAlign={"center"} color={INSTITUTE_COLOUR} href={"#features"}>View breakdown of features</Link>
        </Stack>
    );
}
