import {Checkbox, FormControlLabel, Link, Stack, Typography} from "@mui/material";
import {useContext, useState} from "react";
import {OrganisationContext} from "../../../../../App";
import UserUploadSpreadsheet from "../../../../../Components/UserUploadSpreadsheet";
import {Popup} from "../../../../../Components/Popup";
import {executeCallable, InstituteData, MISQueryParams, Products} from "placementt-core";
import Form from "../../../../../Components/Form";
import InputGroup from "../../../../../Components/FormComponents/InputGroup";
import Preloader from "../../../../../Components/Preloader";

type Params = {
    key: string,
    userType: "Students"|"Staff",
    oId?: string,
    product?: Products,
    cohortId?: string,
    fields?: string[],
    onToggle: (active:boolean) => void,
    active: boolean,
    onComplete?: (jobId?: string) => void
};

export default function UserUploadPopup({oId, product, fields, key, userType, cohortId, onToggle, active, onComplete}:Params) {
    const institute = useContext(OrganisationContext).details;
    const [misPopup, setMisPopup] = useState(false);
    const [usersFromMIS, setUsersFromMIS] = useState<{forename: string, surname: string, email: string, parentEmail?: string}[]>();

    const handleClose = (jobId?: string) => {
        onComplete && jobId && onComplete(jobId);
        onToggle(false);
    };

    return (
        <Popup key={key} maxWidth="lg" fullWidth title={`Add ${userType}`} onClose={() => handleClose()} open={active} cardSx={{minWidth: "400px", maxWidth: "100%"}}>
            <Stack alignContent={"center"} textAlign={"center"} spacing={2}>
                <UserUploadSpreadsheet
                    oId={oId}
                    product={product}
                    onComplete={handleClose}
                    userType={userType}
                    cohortId={cohortId}
                    initialUsers={usersFromMIS}
                    defaultCols={[...["forename", "surname", "email"], ...(fields || institute[`${userType.toLowerCase()}Fields`] || [])]}/>
                {userType === "Students" && <Typography color={"primary"} sx={{textDecoration: "underline", cursor: "pointer"}} onClick={() => setMisPopup(true)}>Or upload from your MIS system</Typography>}
            </Stack>
            {userType === "Students" && <UploadWithMISPopup key={"misPopup"} columns={[...["forename", "surname", "email"], ...(fields || institute[`${userType.toLowerCase()}Fields`] || [])]} open={misPopup} onSubmitData={setUsersFromMIS} onClose={() => setMisPopup(false)}/>}
        </Popup>
    );
}


function UploadWithMISPopup({open, onClose, onSubmitData, columns}:{open: boolean, onClose: () => void, onSubmitData: (students: {forename: string, surname: string, email: string, parentEmail?: string}[]) => void, columns: string[]}) {
    const [loading, setLoading] = useState<"ready"|"loading"|"processing"|"loaded"|"error">("ready");
    const institute = useContext(OrganisationContext).details as InstituteData;

    const getStudents = async (e: {parentEmail: boolean, [key: string]: unknown}) => {
        setLoading("loading");
        const students = await executeCallable("userManagement-getStudentsFromMIS", {data: e}).catch(() => setLoading("error"));
        if (!students) return;
        const studentData = students.data.data.AcademicLevelMembership as {
            student: {
                firstName: string,
                lastName: string,
                emailAddresses: {
                    emailAddress: string
                }[],
                studentGuardianRelationships: {
                    guardian?: {
                        emailAddresses: {
                            emailAddress: string
                        }[]
                    }
                }[]
            }
        }[];
        if (!studentData) return;
        setLoading("processing");
        console.log("STUDENTS", studentData);
        const formattedStudentData:{forename: string, surname: string, email: string, parentEmail?: string}[] = studentData.map((student) => {
            const formattedData:{forename: string, surname: string, email: string, parentEmail?: string, [key: string]: unknown} = {
                forename: student.student.firstName,
                surname: student.student.lastName,
                email: student.student.emailAddresses?.[0]?.emailAddress || "",
                parentEmail: e.parentEmail ? student.student.studentGuardianRelationships[0]?.guardian?.emailAddresses[0]?.emailAddress || "" : "",
            };

            return Object.fromEntries(columns.map((column) => [column, formattedData[column] || ""])) as {forename: string, surname: string, email: string, parentEmail?: string};
        });
        onSubmitData(formattedStudentData);
        onClose();
    };

    return (
        <Popup key={"misPopup"} title={"Upload users from your MIS system"} {...{open, onClose}}>
            {loading === "ready" && <Stack>
                {!institute.authorisedMISSystem && <Typography><Link href={"mailto:admin@placementt.co.uk"}>Contact us</Link> to connect your MIS system and enable user imports.</Typography>}
                {institute.authorisedMISSystem?.name && MISQueryParams?.[institute.authorisedMISSystem?.name] &&
                <Form submitText="Fetch students" onSubmit={(e) => getStudents(e as {parentEmail: boolean, [key: string]: unknown})}>
                    <Typography>Upload students from your MIS system.</Typography>
                    {MISQueryParams[institute.authorisedMISSystem?.name].map((field) => <>{field.description && <Typography>{field.description}</Typography>}<InputGroup required {...{label: field.label, name: field.name, placeholder: field.placeholder}}/></>)}
                    <FormControlLabel control={<Checkbox name={"parentEmail"}/>} label={"Import parent/guardian email?"} />
                </Form>}
            </Stack>}
            {loading === "loading" && <Stack textAlign={"center"}>
                <Preloader visible size="100px"/>
                <Typography variant="h4" color={"primary"}>Importing Arbor data</Typography>
                <Typography variant={"h6"}>This may take a minute, especially if you have a lot of students.</Typography>
            </Stack>}
            {loading === "processing" && <Stack textAlign={"center"}>
                <Preloader visible size="100px"/>
                <Typography variant="h4" color={"primary"}>Data fetched!</Typography>
                <Typography variant={"h6"}>We are processing this into our format.</Typography>
            </Stack>}
        </Popup>
    );
}
