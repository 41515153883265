/* eslint-disable import/no-named-as-default */
/* eslint-disable no-undef */
import {AccountTreeRounded, AnalyticsRounded, Architecture, Assignment, Campaign, CarRepair, ConstructionRounded, DirectionsBoat,
    DocumentScannerRounded,
    Email, LocalCafe, LocalHospital, LocationOn, School, SchoolRounded, SetMeal, SmsRounded, TimelineRounded} from "@mui/icons-material";
import {Box, Button, Card, Icon, List, ListItem, ListItemIcon, ListItemText, styled, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import homepageSVG from "../../Images/uk_homepage.svg";
import {INSTITUTE_COLOUR, PRIMARY_COLOUR, PROVIDER_COLOUR, STUDENT_COLOUR} from "placementt-core";
import {useNavigate} from "react-router-dom";
import TwoColumnSection from "../../Components/Public/TwoColumnSection";
import CardSection from "../../Components/Public/CardSection";
import InfoPageHeader from "../../Components/Public/InfoPageHeader";
import PublicTemplate from "../../Components/Public/PublicTemplate";
import Page from "../../Components/Page";
import {Testimonials} from "./InstituteInfo";


export default function Home() {
    const [screenWidth, setscreenWidth] = useState();
    const navigate = useNavigate();

    const sendEmail = () => {
        window.location = "mailto:admin@placementt.co.uk";
    };

    const handleResize = () => {
        if (window.innerWidth < 700) {
            setscreenWidth("small");
            return;
        }
        if (window.innerWidth < 900) {
            setscreenWidth("medium");
            return;
        }
        setscreenWidth("large");
    };

    // create an event listener
    useEffect(() => {
        window.addEventListener("resize", handleResize);
        handleResize();
    }, []);

    return (
        <Page
            metaTitle="Placementt | Find and Manage Work Experience."
            metaDesc="An ecosystem for students, institutes and businesses to find, organise and manage work experience placements."
            metaKeywords={["work experience tracker", "work experience database", "school work experience", "placement tracker", "placement map"]}
            noHeader>
            <InfoPageHeader
                title="Placementt"
                titleVariant={screenWidth !== "small" ? "h1" : "h2"}
                subtext="An ecosystem for students, institutes and business to manage work placements."
                subtextVariant="h4"
                button={{
                    label: "Get in touch",
                    onClick: () => window.location = "mailto:admin@placementt.co.uk",
                }}
                background={<svg
                    viewBox="0 0 210 94.225677"
                    version="1.1"
                    width={"100%"}
                    height={"600px"}
                    id="svg5"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsSvg="http://www.w3.org/2000/svg"
                    preserveAspectRatio="none">
                    <defs>
                        <linearGradient id='a' gradientUnits='objectBoundingBox' x1='0' y1='0' x2='1' y2='1'>
                            <stop offset='0' stop-color='red'>
                                <animate attributeName="stop-color"
                                    values="#ff1b74;#ffc71b;#ff6b1b;#ff1bfa;#ff1b74" dur="20s" repeatCount="indefinite">
                                </animate>
                            </stop>
                            <stop offset='.5' stop-color='purple'>
                                <animate attributeName="stop-color"
                                    values="#ff1bfa;#ff1b74;#ffc71b;#ff6b1b;#ff1bfa" dur="20s" repeatCount="indefinite">
                                </animate>
                            </stop>
                            <stop offset='1' stop-color='blue'>
                                <animate attributeName="stop-color"
                                    values="#ff6b1b;#ff1bfa;#ff1b74;#ffc71b;#ff6b1b" dur="20s" repeatCount="indefinite">
                                </animate>
                            </stop>
                            <animateTransform attributeName="gradientTransform" type="rotate" from="0 .5 .5" to="360 .5 .5"
                                dur="20s" repeatCount="indefinite" />
                        </linearGradient>
                        <linearGradient id='b' gradientUnits='objectBoundingBox' x1='0' y1='1' x2='1' y2='1'>
                            <stop offset='0' stop-color='red'>
                                <animate attributeName="stop-color"
                                    values="#ff6b1b;#ff1bfa;#ff1b74;#ffc71b;#ff6b1b;" dur="20s" repeatCount="indefinite">
                                </animate>
                            </stop>
                            <stop offset='1' stop-color='purple' stop-opacity="0">
                                <animate attributeName="stop-color"
                                    values="#ff1b74;#ffc71b;#ff6b1b;#ff1bfa;#ff1b74;" dur="20s" repeatCount="indefinite">
                                </animate>
                            </stop>
                            <animateTransform attributeName="gradientTransform" type="rotate" values="360 .5 .5;0 .5 .5" class="ignore"
                                dur="10s" repeatCount="indefinite" />
                        </linearGradient>
                    </defs>
                    <g>
                        <path
                            fill="url(#a)"
                            d="M 0,47.112837 V 0 H 105.00001 210 v 38.76179 38.761792 l -4.82357,1.55739 c -15.88811,5.129813 -31.58656,8.158437 -48.46261,9.349652 -5.40162,0.381278 -19.53728,0.384218 -25.22237,0.0053 -6.74807,-0.44985 -13.90666,-1.2294 -30.68586,-3.341624 C 77.455503,82.15488 74.861877,81.943888 61.797604,81.920964 50.212939,81.900644 47.823278,82.045758 38.927583,83.309834 26.659478,85.053126 13.03445,88.758393 2.2349343,93.288239 L 0,94.225679 Z"
                            id="path1212"/>
                        <path
                            fill="url(#b)"
                            d="M 0,47.112837 V 0 H 105.00001 210 v 38.76179 38.761792 l -4.82357,1.55739 c -15.88811,5.129813 -31.58656,8.158437 -48.46261,9.349652 -5.40162,0.381278 -19.53728,0.384218 -25.22237,0.0053 -6.74807,-0.44985 -13.90666,-1.2294 -30.68586,-3.341624 C 77.455503,82.15488 74.861877,81.943888 61.797604,81.920964 50.212939,81.900644 47.823278,82.045758 38.927583,83.309834 26.659478,85.053126 13.03445,88.758393 2.2349343,93.288239 L 0,94.225679 Z"
                            id="path1212"/>
                    </g>
                </svg>}
                image={
                    <div style={{position: "relative", width: "400px", maxWidth: "80vw"}}>
                        <img style={{width: "100%"}} src={homepageSVG} alt={"A drawing of a map of the United Kingdom, with work experience placements shown at different locations."}/>
                        <MapCard key={0} {...{y: "32%", x: "55%", delay: 0, title: "Daisy's cafe", desc: "⭐⭐⭐⭐", icon: <LocalCafe/>}}/>
                        <MapCard key={1} {...{y: "76%", x: "42%", delay: 10, title: "Docks", desc: "♿ 🅿️ 🚴", icon: <DirectionsBoat/>}}/>
                        <MapCard key={2} {...{y: "70%", x: "10%", delay: 2, title: "Chip shop", desc: "GCSE - A Level", icon: <SetMeal/>}}/>
                        <MapCard key={3} {...{y: "96%", x: "31%", delay: 6, title: "Mechanics", desc: "Trainee mechanic", icon: <CarRepair/>}}/>
                        <MapCard key={4} {...{y: "45%", x: "14%", delay: 8, title: "Vets", desc: "Undergraduate", icon: <School/>}}/>
                        <MapCard key={5} {...{y: "58%", x: "63%", delay: 4, title: "Graphic design", desc: "Added 20/09/22", icon: <Architecture/>}}/>
                        <MapCard key={8} {...{y: "38%", x: "58%", delay: 15, title: "Hospital", desc: "Hosted 119 students", icon: <LocalHospital/>}}/>
                    </div>
                }
            />
            <PublicTemplate>
                <TwoColumnSection
                    title="Supporting student placements, from secondary school to university"
                    sectionOne="Placementt is an online platform that supports institutes, students and businesses find, organise and manage work placements. Our all-in-one placement ecosystem maximises student potential, with seamless placement tracking and skills development."
                    sectionTwo="We provide platforms for institutes, students and businesses, tied together through a comprehensive UK-wide placement database and robust communication channels."
                />
                <Testimonials color={PRIMARY_COLOUR}/>
                <CardSection
                    title="Your complete placement platform"
                    items={[
                        {
                            title: "For institutes",
                            primaryColor: INSTITUTE_COLOUR,
                            summary: <><Typography fontWeight={"bold"} color={INSTITUTE_COLOUR} display={"inline"}>Manage your work experience programme</Typography> with ease. Our automated placement management workflow will reduce admin and give you more time to spend on student support.</>,
                            button: {
                                label: "Learn more",
                                onClick: () => navigate("institute"),
                                color: "institutes",
                            },
                            secondaryContent:
                                <List>
                                    <ListItem>
                                        <ListItemIcon><AccountTreeRounded/></ListItemIcon>
                                        <ListItemText primary="Customisable workflow"/>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon><DocumentScannerRounded/></ListItemIcon>
                                        <ListItemText primary="Automatic insurance and risk assessment acquisition"/>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon><SmsRounded/></ListItemIcon>
                                        <ListItemText primary="Robust communication tools"/>
                                    </ListItem>
                                </List>,
                        },
                        {
                            title: "For students",
                            primaryColor: STUDENT_COLOUR,
                            summary: <><Typography fontWeight={"bold"} color={STUDENT_COLOUR} display={"inline"}>Maximise your potential</Typography> with a platform that lets you find the best placements, tracks your progress and helps you develop your skills.</>,
                            button: {
                                label: "Learn more",
                                onClick: () => navigate("student"),
                                color: "students",
                            },
                            secondaryContent:
                            <List>
                                <ListItem>
                                    <ListItemIcon><TimelineRounded/></ListItemIcon>
                                    <ListItemText primary="Simple placement tracking"/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><AnalyticsRounded/></ListItemIcon>
                                    <ListItemText primary="Customisable analytics"/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><ConstructionRounded/></ListItemIcon>
                                    <ListItemText primary="Skills development tools"/>
                                </ListItem>
                            </List>,
                        },
                        {
                            title: "For providers",
                            primaryColor: PROVIDER_COLOUR,
                            summary: <><Typography fontWeight={"bold"} color={PROVIDER_COLOUR} display={"inline"}>Secure top-tier students</Typography> for your placements. We support and enhance your placement adverts, allow you to track your students and take care of all needless admin.</>,
                            button: {
                                label: "Learn more",
                                onClick: () => navigate("provider"),
                                color: "providers",
                            },
                            secondaryContent:
                            <List>
                                <ListItem>
                                    <ListItemIcon><SchoolRounded/></ListItemIcon>
                                    <ListItemText primary="Manage placement students"/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><Campaign/></ListItemIcon>
                                    <ListItemText primary="Advertise your opportunities"/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><Assignment/></ListItemIcon>
                                    <ListItemText primary="Automated school admin"/>
                                </ListItem>
                            </List>,
                        },
                    ]}/>
                <Button sx={{margin: "auto", fontSize: "1.5em", marginBottom: "50px", paddingLeft: 4, paddingRight: 4}} onClick={sendEmail} startIcon={<Email/>}>Get in touch</Button>
            </PublicTemplate>
        </Page>
    );
}


const MapCardContainer = styled(Box)`
    position: absolute;
    left: ${(props) => props.left};
    top: ${(props) => props.top};
    transition: all 3s ease-in-out;
    transform: translateY(-100%);
`;

function MapCard({x, y, delay, title, desc, icon, key}) {
    // eslint-disable-next-line no-unused-vars
    const [, setVisible] = useState(4);

    useEffect(() => {
        const timer =
            setTimeout(() => {
                setVisible(5);
                startTimer();
            }, (delay*1000));

        return () => clearTimeout(timer);
    }, []);

    const startTimer = () => {
        setInterval(() => {
            setVisible((v) => v === 5 ? 0 : v + 1);
        }, 3000);
    };

    return (
        <MapCardContainer key={key+"container"} left={x} top={y} sx={{opacity: /* visible === 5 ? 1: */0}}>
            <Card sx={{marginBottom: "5px"}}>
                <ListItem>
                    {icon && <ListItemIcon>{icon}</ListItemIcon>}
                    <ListItemText primary={title} secondary={desc}/>
                </ListItem>
            </Card>
            <Icon sx={{color: "#8f45ff", boxShadow: "0px 5px 4px -4px black"}}><LocationOn/></Icon>
        </MapCardContainer>
    );
}
