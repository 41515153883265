import {Button, Chip, Divider, Grid, ListItemButton, ListItemSecondaryAction, ListItemText, Stack} from "@mui/material";
import SubNavBar from "../../Components/Navigation/SubNavBar";
import Page from "../../Components/Page";
import {useEffect, useState} from "react";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import {Blog, camelCaseToNormal, capitaliseWords, convertDate} from "placementt-core";
import FilterList from "../../Components/FilterList";
import {useNavigate} from "react-router-dom";
import CreateBlog from "./CreateBlog";
import IconButtonPop from "../../Components/IconButtonPop";
import {Delete} from "@mui/icons-material";
import DeletePopup from "../../Components/DeletePopup";

export default function Blogs() {
    return (
        <Page
            metaTitle="Admin | Blogs"
            noGutters>
            <SubNavBar
                items={[
                    {
                        label: "My Blogs",
                        route: "all",
                        element: <BlogsPage/>,
                    },
                    {
                        route: "create",
                        element: <CreateBlog/>,
                    },
                    {
                        route: ":id",
                        element: <CreateBlog/>,
                    },
                ]}/>
        </Page>
    );
}

function BlogsPage() {
    const [blogs, setBlogs] = useState<{[key: string]: Blog}>();
    const [deletePopup, setDeletePopup] = useState<string>();
    const firebaseQuery = new FirebaseQuery();
    const navigate = useNavigate();

    useEffect(() => {
        firebaseQuery.getDocsWhere("blogs").then((blog) => setBlogs(blog as {[key: string]: Blog}));
    }, []);

    console.log("blogs", blogs);
    return (
        <Page
            title="Blogs"
            titleSecondary={<Button onClick={() => navigate("../create")}>New blog</Button>}
        >
            <FilterList title="Published blogs" data={blogs} loadMoreIcon={false} onScrollBottom={() => null}>
                {blogs && Object.entries(blogs).map(([key, blog]) =>
                    <ListItemButton key={key} id={key} divider onClick={() => navigate(`../${key}`)}>
                        <ListItemText
                            primary={blog.title}
                            secondary={capitaliseWords(camelCaseToNormal(blog.author))+" | "+convertDate(blog.uploaded, "visual")}
                        />
                        <ListItemSecondaryAction>
                            <Stack spacing={0} alignItems={"end"}>
                                <Stack direction={"row"}>
                                    <Chip label={capitaliseWords(blog.category)} color={"primary"}/>
                                    <Divider orientation="vertical" sx={{height: "25px"}}/>
                                    {blog?.tags?.split(",").map((tag: string, index: number) => {
                                        return (
                                            <Grid item key = {index}><Chip label = {tag} color = {"primary"}/></Grid>
                                        );
                                    })}
                                </Stack>
                                <IconButtonPop title="Delete" onClick={(e) => {
                                    setDeletePopup(key);
                                    e.stopPropagation();
                                }}><Delete/></IconButtonPop>
                            </Stack>
                        </ListItemSecondaryAction>
                    </ListItemButton>
                )}
            </FilterList>
            <DeletePopup title="blog" itemName={blogs?.[deletePopup || ""]?.title} open={Boolean(deletePopup)} onClose={() => setDeletePopup(undefined)} onDelete={() => firebaseQuery.delete(["blogs", deletePopup || ""]).then(() => setDeletePopup(undefined))}/>
        </Page>
    );
}
