/* Three column component section component created to display testimonials on the institutes page.
 * Each of the 3 sections contains a 100x100 image with an image title next to it and a bigger block of text under both of them.
*/

import {Grid, Stack, Typography, Box} from "@mui/material";
import {ReactNode} from "react";

type Section = {
    color: string,
    image: string,
    imageTitle: string,
    text: ReactNode
}

type Params = {
    title: string,
    sectionOne: Section,
    sectionTwo: Section,
    sectionThree: Section,
    titleColor?: string
};

export default function ThreeColumnSection({title, sectionOne, sectionTwo, sectionThree, titleColor="primary"}: Params) {
    return (
        <Stack>
            {title && <Typography textAlign="center" color={titleColor} variant="h4" pb={4}>{title}</Typography>}
            <Grid container>
                <Grid item xs={12} md={4} borderRight={"1px dashed lightgrey"} p={2}>
                    <Box display="flex" alignItems="center">
                        <Box component="img" src={sectionOne.image} maxWidth={100} height={100} mr={2} />
                        <Typography color={sectionOne.color} variant="h6">{sectionOne.imageTitle}</Typography>
                    </Box>
                    <Typography fontSize={"1.2em"} mt={2}>{sectionOne.text}</Typography>
                </Grid>
                <Grid item xs={12} md={4} borderRight={"1px dashed lightgrey"} p={2}>
                    <Box display="flex" alignItems="center">
                        <Box component="img" src={sectionTwo.image} width={100} height={100} mr={2} />
                        <Typography color={sectionTwo.color} variant="h6">{sectionTwo.imageTitle}</Typography>
                    </Box>
                    <Typography fontSize={"1.2em"} mt={2}>{sectionTwo.text}</Typography>
                </Grid>
                <Grid item xs={12} md={4} p={2}>
                    <Box display="flex" alignItems="center">
                        <Box component="img" src={sectionThree.image} width={100} height={100} mr={2} />
                        <Typography color={sectionThree.color} variant="h6">{sectionThree.imageTitle}</Typography>
                    </Box>
                    <Typography fontSize={"1.2em"} mt={2}>{sectionThree.text}</Typography>
                </Grid>
            </Grid>
        </Stack>
    );
}
