import {Unsubscribe} from "@mui/icons-material";
import {Route, Routes} from "react-router-dom";
import NotFound from "./NotFound";
import Privacy from "./Privacy";
import ProviderInfo from "./ProviderInfo";
import InstituteInfo from "./InstituteInfo";
import StudentInfo from "./StudentInfo";
import PlacementReview from "./PlacementReview";
import SearchPlacements from "../Shared/SearchPlacements";
import UploadInsurance from "./UploadInsurance";
import BusinessSignUp from "./BusinessSignUp";
import Home from "./Home";
import ExternalPlacementSubmission from "./ExternalPlacementSubmission";
import BlogPage from "./BlogPage";
import Terms from "./Terms";
import Blogs from "./Blogs";
import StudentAppAdvert from "./AppAdvert";
import AboutUs from "./AboutUs";
import PlacementRequest from "./PlacementRequest";

export default function PublicRoutes() {
    return (
        <Routes>
            <Route path='*' element={<NotFound />} />
            <Route path="/" element={<Home/> /* <Home/> when launch, which shows the map*/}/>

            {/* <Route path="/about" element={<About/>}/>*/}
            <Route path="/review/:uid/:pId/:key" element={<PlacementReview/>}/>
            <Route path="/review/:uid/:pId/:key/:reject" element={<PlacementReview/>}/>
            <Route path="/submitPlacement/:iId/:placementId" element={<ExternalPlacementSubmission/>}/>
            <Route path="/submitPlacement/:iId" element={<ExternalPlacementSubmission/>}/>
            <Route path="/submitPlacement" element={<ExternalPlacementSubmission/>}/>
            <Route path="/blogs" element={<Blogs/>}/>

            <Route path="/insurance/:providerId" element={<UploadInsurance/>}/>
            <Route path="/insurance/:providerId/:placementId" element={<UploadInsurance/>}/>
            <Route path="/placementRequest/:pId" element={<PlacementRequest/>}/>

            <Route path="/privacy" element={<Privacy/>}/>
            <Route path="/terms" element={<Terms/>}/>
            <Route path="/unsubscribe" element={<Unsubscribe/>}/>
            <Route path="/provider" element={<ProviderInfo/>}/>
            <Route path="/institute" element={<InstituteInfo/>}/>
            <Route path="/student" element={<StudentInfo/>}/>
            <Route path="/search" element={<SearchPlacements/>}/>
            <Route path="blogs/:id" element = {<BlogPage/>}/>
            <Route path="/business-waitlist" element={<BusinessSignUp/>}/>
            <Route path="/app-student" element={<StudentAppAdvert/>}/>
            <Route path="/about-us" element={<AboutUs/>}/>
        </Routes>
    );
}
