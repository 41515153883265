import {Close, ErrorOutline} from "@mui/icons-material";
import {Alert, Button, Stack} from "@mui/material";
import {FlagCodes, InstituteData, StudentPlacementData, UserData, WorkflowStage, flagCodes, getPlacementFlagCodes} from "placementt-core";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import {useContext, useEffect, useState} from "react";
import {OrganisationContext, UserContext} from "../App";
import IconButtonPop from "../Components/IconButtonPop";

type Params = {
    placement:StudentPlacementData,
    workflow: WorkflowStage[],
    icon?: boolean,
    student: UserData,
    onClick?:(e:any) => void
}
export const RenderPlacementFlags = ({placement, workflow, icon=false, student, onClick}:Params) => {
    const [studentData, setStudentData] = useState<UserData>();
    const [flags, setFlags] = useState<FlagCodes[]>();

    const firebaseQuery = new FirebaseQuery();

    const institute = useContext(OrganisationContext)?.details as InstituteData;
    const user = useContext(UserContext) as UserData;

    useEffect(() => {
        const getStudentData = async () => {
            return student ? student : await firebaseQuery.getDocData(["users", placement.uid]) as UserData;
        };
        getStudentData().then((data) => {
            setStudentData(data);
        });
    }, []);

    useEffect(() => {
        if (!studentData) return;
        getPlacementFlagCodes({...{placement, institute, user, studentData, workflow}}).then(setFlags);
    }, [studentData]);

    if (!flags) return null;
    return (
        <RenderFlags {...{flags, icon}} onClick={onClick}/>
    );
};

export const RenderFlags = ({flags, icon, onClick}:{flags:FlagCodes[], icon:boolean, onClick?:(e:any, close?: boolean) => void}) => {
    if (flags.length === 0) {
        return null;
    }

    if (icon) {
        const flagSummary = {
            errors: flags.filter((flag) => flagCodes[flag].severity === "error")?.length || 0,
            warnings: flags.filter((flag) => flagCodes[flag].severity === "warning").length || 0,
            info: flags.filter((flag) => flagCodes[flag].severity === "info")?.length || 0,
        };
        let text = "";

        if (flagSummary.errors > 0) {
            text = text + `${flagSummary.errors} error${flagSummary.errors > 1 ? "s" : ""}. `;
        }
        if (flagSummary.warnings > 0) {
            text = text.concat(`${flagSummary.warnings} warning${flagSummary.warnings > 1 ? "s" : ""}.`);
        }

        return (
            <IconButtonPop responsive={false} title={flags.length > 1 ? text : flagCodes[flags[0]].message}>
                <ErrorOutline color={flagSummary.errors > 0 ? "error" : flagSummary.warnings > 0 ? "warning" : "primary"}/>
            </IconButtonPop>
        );
    }

    return (
        <Stack spacing={2}>
            {flags.map((flag:FlagCodes) =>
                <Alert severity={flagCodes[flag].severity} sx={{alignItems: "center"}} action={<>{flagCodes[flag].button ? <Button color="inherit" size="small" onClick={() => onClick && onClick(flag)}>{flagCodes[flag].button}</Button> : null}{flagCodes[flag].onClose ? <IconButtonPop responsive={false} title="Dismiss" onClick={() => onClick && onClick(flag, true)}><Close/></IconButtonPop> : null}</>}>{flagCodes[flag].message}</Alert>)}
        </Stack>
    );
};
