import {Checkbox, Divider, FormControlLabel, FormGroup, Grid, Typography} from "@mui/material";
import {OrganisationAddress} from "placementt-core";
import {ChangeEvent, useContext, useEffect, useState} from "react";
import InputGroup from "../../../Components/FormComponents/InputGroup";
import Form from "../../../Components/Form";
import Dropdown from "../../../Components/FormComponents/Dropdown";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import {Popup} from "../../../Components/Popup";
import {UserContext} from "../../../App";
import ListMaker from "../../../Components/FormComponents/ListMaker";
import {where} from "firebase/firestore";

type PopupParams = {
    onToggle: (open: boolean) => void,
    active: boolean,
    fAddressData?: OrganisationAddress,
    onSubmit?: (e: OrganisationAddress) => void,
    initialAddress?: OrganisationAddress;
    addressId?: string
}

export default function AddAddressPopup({onToggle, active, onSubmit, initialAddress, addressId}: PopupParams) {
    const [inputKey, setInputKey] = useState((Math.random() + 1).toString(36).substring(7));
    const [fAddressData, setFfAddressData] = useState<OrganisationAddress|undefined>(initialAddress);

    const firebaseQuery = new FirebaseQuery();

    useEffect(() => {
        setFfAddressData(initialAddress);
    }, [initialAddress]);

    const user = useContext(UserContext);

    if (user.product !== "providers" && user.product !== "institutes") throw new Error("AddAddressPopup for organisations only");

    useEffect(() => {
        setFfAddressData(fAddressData);
    }, [fAddressData]);
    // Remove all users already active from list.
    const onSubmitAddress = async () => {
        if (!fAddressData) return;

        if (initialAddress?.default && !fAddressData.default) {
            throw new Error("Cannot remove default from this address. Go to another address and set it as default.");
        }

        if (fAddressData.default && !initialAddress?.default) {
            // Has been set as default. Need to remove default from any others.
            const addressCurrentlyDefault = await firebaseQuery.getDocsWhere(["addresses"], [where("product", "==", user.product), where("oId", "==", user.oId), where("default", "==", true)]) as {[key: string]: OrganisationAddress};
            await Promise.all(Object.keys(addressCurrentlyDefault).map(async (addressId) => {
                await firebaseQuery.update(["addresses", addressId], {default: false});
            }));
        }

        const addressUploadData = {
            ...fAddressData,
            country: fAddressData.country || "unitedKingdom",
            stage: "complete",
            oId: user.oId,
            product: user.product,
            created: (new Date()).toISOString(),
        } as OrganisationAddress;

        let fAddressId:string;
        if (addressId) {
            await firebaseQuery.update(["addresses", addressId], addressUploadData);
            fAddressId = addressId;
        } else {
            fAddressId = (await firebaseQuery.add("addresses", addressUploadData)).id;
        }

        onSubmit && onSubmit({...(addressUploadData), id: fAddressId});

        setInputKey((Math.random() + 1).toString(36).substring(7));
    };

    return (
        <Popup title={"New address"} open={active} key={inputKey} onClose={() => {
            onToggle(false); setFfAddressData(undefined);
        }}>
            <Form onSubmit={onSubmitAddress}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h5">Basic address</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <InputGroup required id="name" label="Name (private)" name="name" onChange={(e: ChangeEvent<HTMLInputElement>) => setFfAddressData((a) => ({...(a || {}), name: e.target.value}) as OrganisationAddress)} value={fAddressData?.name}/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputGroup required id="address-line1" label="Address line one" name="address-line1" onChange={(e: ChangeEvent<HTMLInputElement>) => setFfAddressData((a) => ({...(a || {}), ["address-line1"]: e.target.value}) as OrganisationAddress)} value={fAddressData?.["address-line1"]}/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputGroup id="address-line2" label="Address line two" name="address-line2" onChange={(e: ChangeEvent<HTMLInputElement>) => setFfAddressData((a) => ({...(a || {}), ["address-line2"]: e.target.value}) as OrganisationAddress)} value={fAddressData?.["address-line2"]}/>
                    </Grid>
                    <Grid item xs={12}>
                        <InputGroup required id="locality" label="City" name="locality" onChange={(e: ChangeEvent<HTMLInputElement>) => setFfAddressData((a) => ({...(a || {}), locality: e.target.value}) as OrganisationAddress)} value={fAddressData?.locality}/>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <InputGroup required id="postcode" label="Postcode" name="postal_code" onChange={(e: ChangeEvent<HTMLInputElement>) => setFfAddressData((a) => ({...(a || {}), postal_code: e.target.value}) as OrganisationAddress)} value={fAddressData?.postal_code}/>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Dropdown required id="country" label="Country" name="country" onChange={(e) => setFfAddressData((a) => ({...(a || {}), country: e.target.value}) as OrganisationAddress)} value={fAddressData?.country}/>
                    </Grid>
                    <Grid item xs={12}>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox name={"default"} onChange={(e) => setFfAddressData((a) => ({...(a || {}), default: e.target.checked}) as OrganisationAddress)} defaultChecked={fAddressData?.default}/>} label={"Default address"} />
                        </FormGroup>
                    </Grid>
                    {user.product === "providers" && <>
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h5">Contacts</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <ListMaker name={"reception"} noDefault header label="Reception contacts" multipleInputs onChange={(e) => setFfAddressData((a) => ({...(a || {}), contacts: {...(a ? a.contacts || {} : {}), reception: e}}) as OrganisationAddress)} items={fAddressData?.contacts?.reception}>
                                <InputGroup name={"name"} label={"Name"} required/>
                                <InputGroup name={"email"} label={"Email"} type={"email"}/>
                                <InputGroup name={"phone"} label={"Phone"} type={"phone"}/>
                            </ListMaker>
                        </Grid>
                        <Grid item xs={12}>
                            <ListMaker name={"firstAid"} noDefault header label="First aiders" multipleInputs onChange={(e) => setFfAddressData((a) => ({...(a || {}), contacts: {...(a ? a.contacts || {} : {}), firstAid: e}}) as OrganisationAddress)} items={fAddressData?.contacts?.firstAid}>
                                <InputGroup name={"name"} label={"Name"} required/>
                                <InputGroup name={"email"} label={"Email"} type={"email"}/>
                                <InputGroup name={"phone"} label={"Phone"} type={"phone"}/>
                            </ListMaker>
                        </Grid>
                        <Grid item xs={12}>
                            <ListMaker name={"security"} noDefault header label="Security / Emergency contacts" multipleInputs onChange={(e) => setFfAddressData((a) => ({...(a || {}), contacts: {...(a ? a.contacts || {} : {}), security: e}}) as OrganisationAddress)} items={fAddressData?.contacts?.security}>
                                <InputGroup name={"name"} label={"Name"} required/>
                                <InputGroup name={"email"} label={"Email"} type={"email"}/>
                                <InputGroup name={"phone"} label={"Phone"} type={"phone"}/>
                            </ListMaker>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h5">Additional information</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel label={"On-site parking"} control={<Checkbox checked={fAddressData?.additionalQuestions?.parking}/>}/>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel label={"Bike storage"} control={<Checkbox/>}/>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel label={"Lunch available"} control={<Checkbox/>}/>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel label={"Disabled access"} control={<Checkbox/>}/>
                        </Grid>
                    </>}
                </Grid>
            </Form>
        </Popup>
    );
}
