import {Box, Divider, List, Stack, Typography} from "@mui/material";
import {Children, isValidElement, ReactNode, UIEvent, useEffect, useState} from "react";
import Preloader from "./Preloader";
import {SearchBox} from "./SearchBox";
import FilterSelector from "./FilterSelector";
import Card from "./Card";
import {FilterObject} from "./FilterTable";

// editCols must be a link to the collection where to alter docs

type Params = {
    title?: string|ReactNode,
    data?: {[key:string]: {[key:string]: unknown}},
    children?: ReactNode,
    sx?: {[key:string]: unknown},
    actionButton?: ReactNode,
    onScrollBottom?: () => void,
    loadMoreIcon?: boolean,
    onSearch?: (query:string) => void,
    noResultsText?: ReactNode,
    card?: boolean,
    hideNoResultsText?: boolean,
    filters?: FilterObject
    urlRef?: string,
    grid?: boolean,
    xs?: number,
    sm?: number,
    md?: number,
    lg?: number,
    noSearch?: boolean
}

export default function FilterList({title, data, children, sx, noSearch, actionButton, onScrollBottom, loadMoreIcon, onSearch, noResultsText="No results", card=true, hideNoResultsText, filters, urlRef, grid, xs, sm, md, lg}:Params) {
    const [tableData, setTableData] = useState<string[]>([]);
    const [bottom, setBottom] = useState(false);

    const handleScroll = (e:UIEvent<HTMLElement>) => {
        console.log("SCROLLING");
        const scrolledToBottom = Math.floor((e.currentTarget.scrollHeight - e.currentTarget.scrollTop)/10) === Math.floor(e.currentTarget.clientHeight/10);

        if (scrolledToBottom && !bottom) {
            setBottom(true);
            onScrollBottom && onScrollBottom();
        } else if (!scrolledToBottom) {
            setBottom(false);
        }
    };

    useEffect(() => {
        if (!data) return;
        setTableData(Object.keys(data));
    }, [data]);

    const search = (query:string) => {
        if (onSearch) {
            onSearch(query);
            return;
        }
        if (!data) return;
        setTableData(() => Object.keys(data).filter((key) => JSON.stringify(data[key]).includes(query)));
    };

    const returnItem = <>
        <List style = {{marginBottom: "50px"}}>
            {Children.map(children, (child) => {
                if (!isValidElement(child)) {
                    return child;
                }

                if (!tableData.includes(child.props?.id)) {
                    return null;
                }
                return child;
            })}
            {loadMoreIcon ? <Preloader visible/> : Children.count(children) > 0 ? hideNoResultsText ? null : <Divider sx={{marginTop: "10px"}}>End of results</Divider> : <Divider>{noResultsText}</Divider>}
        </List>
    </>;

    const header = [
        <Typography variant="h5">{title}</Typography>,
        <Stack key={"search"} direction={"row"} alignItems={"center"} spacing={1}>
            {filters && <FilterSelector filters={filters} urlRef={urlRef}/>}
            {noSearch || <SearchBox placeholder={"Search"} onSearch={search}/>}
            {actionButton}
        </Stack>];

    if (card) {
        return (
            <Card sx={{height: "100%", ...sx}} {...{grid, xs, sm, md, lg}} title={header}>
                <div onScroll={handleScroll} style={{height: "100%", overflow: "auto"}}>
                    {returnItem}
                </div>
            </Card>
        );
    }
    return (
        <Box sx={{height: "100%", pt: "20px", ...sx}}>
            <Stack direction={"row"} justifyContent={"space-between"}>{header}</Stack>
            <div style={{height: "100%", overflow: "auto"}} onScroll={handleScroll}>
                {returnItem}
            </div>
        </Box>
    );
}
