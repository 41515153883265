import {Event} from "@mui/icons-material";
import {
    CardContent,
    Grid,
    ListItem,
    ListItemIcon,
    Stack,
    Typography,
} from "@mui/material";
import {StudentPlacementData, camelCaseToNormal, capitaliseWords, convertDate, getDateDiff, reformatDate} from "placementt-core";
import {useContext, useEffect, useState} from "react";
import {UserContext} from "../../../App";
import NotesNew from "../../Shared/NotesNew";
import Page from "../../../Components/Page";
import NextOrActivePlacementCard from "../../../Components/NextOrActivePlacementCard";
import Card from "../../../Components/Card";
import StudentPlacementList from "../../../Components/StudentPlacementList";
import TutorialPage from "../../Shared/TutorialPage";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import {where} from "firebase/firestore";
import {PurpleCard} from "../../../Util/styledComponents";
import {useNavigate} from "react-router-dom";
import DailyPlacementLog from "../../../Components/DailyPlacementLog";
import ReportPlacementPopup from "../../../Components/ReportPlacementPopup";
import {useCheckMobileScreen} from "../../../Util/util";
import NotificationBanner from "../../../Components/NotificationBanner";

export default function Home() {
    const user = useContext(UserContext);
    const [activePlacement, setActivePlacement] = useState<StudentPlacementData>();
    const firebaseQuery = new FirebaseQuery();

    useEffect(() => {
        firebaseQuery.getDocsWhere("placements", [where("uid", "==", user.id), where("active", "==", true)]).then((item) => setActivePlacement((item as {[key: string]: StudentPlacementData})[Object.keys(item as {[key: string]: StudentPlacementData})[0]]));
    }, []);

    return (
        <Page
            title={`Welcome back, ${user.details.forename}!`}
            metaTitle="Placementt | Home"
            metaDesc="View your Placementt - Educator student account."
            grid>
            <TutorialPage pageId="instituteStudentHome"/>
            {activePlacement ? <ActivePlacementHome placement={activePlacement}/> : <DefaultHome/>}
        </Page>
    );
}


function DefaultHome() {
    const user = useContext(UserContext);

    return (
        <>
            <Grid container item md={12} lg={8}>
                <NotificationBanner grid/>
                <NextOrActivePlacementCard/>
                <Grid item xs={12} sm={6}>
                    <Card
                        sx={{
                            borderLeft: "8px solid #005e9e",
                            padding: "5px",
                        }}
                    >
                        <Typography variant="subtitle1">
                                Placement dates
                        </Typography>
                        <ListItem>
                            <ListItemIcon>
                                <Event
                                    sx={{
                                        height: 30,
                                        width: 30,
                                    }}
                                />
                            </ListItemIcon>
                            {user.cohortData && <Typography variant="body1">{`${ user.cohortData.startPlacements && reformatDate(
                                user.cohortData.startPlacements
                            )} - ${user.cohortData.endPlacements && reformatDate(
                                user.cohortData.endPlacements
                            )}`}</Typography>}
                        </ListItem>
                    </Card>
                </Grid>
                {/* <Grid item flex={1} width minHeight={'400px'} maxHeight={'400px'}>
                    <Boards/>
                </Grid>*/}
                <Grid item xs={12}>
                    <StudentPlacementList
                        sx={{minHeight: "450px", maxHeight: "450px"}}
                        title={"Placements"}
                    />
                </Grid>
            </Grid>
            <Grid item md={0} lg={4}>
                <NotesNew/>
            </Grid>
        </>
    );
}

function ActivePlacementHome({placement}:{placement: StudentPlacementData}) {
    const user = useContext(UserContext);
    const navigate = useNavigate();
    const today = new Date();
    const mobile = useCheckMobileScreen();

    return (
        <>
            <Grid container item md={12} lg={8}>
                <Grid item xs={12}>
                    <NotificationBanner/>
                    <PurpleCard onClick={() => navigate(`/${user.product}/placements/${placement.id}`)}>
                        <CardContent>
                            <Grid container>
                                <Grid item sm={8}>
                                    <Stack justifyContent={"space-between"} height={"100%"} spacing={0}>
                                        <Typography fontSize={20}>Active placement</Typography>
                                        <Typography variant="h4">{placement.name}</Typography>
                                        <Typography>{placement.parentEmail}</Typography>
                                        <Typography>{placement["address-line1"]} | {placement.locality} | {placement.postal_code} | {capitaliseWords(camelCaseToNormal(placement.country))}</Typography>
                                        <Typography variant="body1">
                                            {convertDate(placement.startDate, "visual") as string}{" "}-{" "}
                                            {convertDate(placement.endDate, "visual") as string}
                                        </Typography>
                                    </Stack>
                                </Grid>
                                {mobile || <Grid item xs={12} sm={4} display={"flex"} flexDirection={"column"} alignItems={"center"}>
                                    <Typography variant={"h1"} width={"max-content"} sx={{marginBottom: "-10px"}}>
                                        {getDateDiff(today, new Date(placement.endDate))}
                                    </Typography>
                                    <Typography variant="subtitle1" width={"max-content"}>
                                    days left
                                    </Typography>
                                </Grid>}
                                <Grid item xs={12}>
                                    <ReportPlacementPopup placement={placement} buttonColor="white"/>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </PurpleCard>
                </Grid>

                <Grid item xs={12}>
                    <DailyPlacementLog placement={placement} card userType="students" access={["students"]}/>
                </Grid>
                {/* <Grid item flex={1} width minHeight={'400px'} maxHeight={'400px'}>
                    <Boards/>
                </Grid>*/}

            </Grid>
            <Grid item container md={0} lg={4}>
                <Grid item xs={12}>
                    <NotesNew/>
                </Grid>
                <Grid item xs={12}>
                    <StudentPlacementList
                        sx={{minHeight: "450px", maxHeight: "450px"}}
                        title={"Placements"}
                    />
                </Grid>
            </Grid>
        </>
    );
}
