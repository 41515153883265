import {Divider, Stack} from "@mui/material";
import {Children, ReactNode} from "react";

type Params = {
    children?: ReactNode[] | ReactNode
}

export default function PublicTemplate({children}: Params) {
    return (
        <Stack alignItems={"center"} p={8} spacing={8} width={"100%"} maxWidth={"1280px"} alignSelf={"center"}>
            {Children.map(children, (child, index) =>
                <>
                    {index > 0 && <Divider sx={{borderStyle: "dashed", width: "100%"}}/>}
                    {child}
                </>)}
        </Stack>
    );
}
