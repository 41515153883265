import {where} from "firebase/firestore";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import {useContext, useEffect, useState} from "react";
import {UserContext} from "../../../../App";
import {CohortData, providerSectors} from "placementt-core";
import {useNavigate} from "react-router-dom";
import {BarChart, PieChart} from "../../../../Components/Charts";


export function ActiveInactiveStudentsGraph({cohortId}:{cohortId?: string}) {
    const [inactive, setInactive] = useState<number>();
    const [activeNotLoggedIn, setActiveNotLoggedIn] = useState<number>();
    const [active, setActive] = useState<number>();

    const firebaseQuery = new FirebaseQuery();
    const user = useContext(UserContext);
    const navigate = useNavigate();

    useEffect(() => {
        const constraints = [where("userType", "==", "Students"), where("oId", "==", user.oId)];
        if (cohortId) {
            constraints.push(where("cohort", "==", cohortId));
        }
        firebaseQuery.getCount("users", [...constraints, where("status", "==", "active"), where("active", "==", false)]).then(setActiveNotLoggedIn);
        firebaseQuery.getCount("users", [...constraints, where("status", "==", "active")]).then(setActive);
        firebaseQuery.getCount("users", [...constraints, where("status", "==", "inactive")]).then(setInactive);
    }, []);

    const data = {
        ["Active"]: {
            value: active,
            callback: () => navigate(`/institutes/cohorts/${cohortId}/students/?status=active`),
        },
        ["Active, not logged in"]: {
            value: activeNotLoggedIn,
            callback: () => navigate(`/institutes/cohorts/${cohortId}/students/?status=active&active=false`),
        },
        ["Inactive"]: {
            value: inactive,
            callback: () => navigate(`/institutes/cohorts/${cohortId}/students/?status=inactive`),
        },
    };

    return (<BarChart title="Active student accounts" subtitle="Click to view students." data={data}/>);
}

export function StudentsWithPlacementsGraph({cohortId}:{cohortId?: string}) {
    const [withPlacements, setWithPlacements] = useState<number>();
    const [withoutPlacements, setWithoutPlacements] = useState<number>();

    const firebaseQuery = new FirebaseQuery();
    const user = useContext(UserContext);
    const navigate = useNavigate();

    useEffect(() => {
        const constraints = [where("userType", "==", "Students"), where("oId", "==", user.oId)];
        if (cohortId) {
            constraints.push(where("cohort", "==", cohortId));
        }
        firebaseQuery.getCount("users", [...constraints, where("placements", "==", 0)]).then(setWithoutPlacements);
        firebaseQuery.getCount("users", [...constraints, where("placements", ">", 0)]).then(setWithPlacements);
    }, []);

    const data = {
        ["With placements"]: {
            value: withPlacements,
            callback: () => navigate(`/institutes/cohorts/${cohortId}/students/?placements=true`),

        },
        ["Without placements"]: {
            value: withoutPlacements,
            callback: () => navigate(`/institutes/cohorts/${cohortId}/students/?placements=false`),
        },
    };

    return (<BarChart title="Students with placements" subtitle="Click to view students." data={data}/>);
}


export function PlacementStagesGraph({cohortData}:{cohortData: CohortData}) {
    const [placementsInStages, setPlacementsInStages] = useState<{[key: string]: {value: number, callback?:() => void}}>(Object.fromEntries(cohortData.workflow.map((stage) => [stage.name, {value: 0}])));

    const firebaseQuery = new FirebaseQuery();
    const user = useContext(UserContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (!cohortData) return;
        const constraints = [where("oId", "==", user.oId), where("cohort", "==", cohortData.id)];
        Promise.all(cohortData.workflow.map(async (stage) => {
            const placementsAtStage = await firebaseQuery.getCount("placements", [...constraints, where("status", "==", stage.id)]);
            return [stage.name, {value: placementsAtStage, callback: () => navigate(`/institutes/cohorts/${cohortData.id}/placements?id=upcoming&status=${stage.id}`)}];
        })).then((placementsInStagesArray) => {
            setPlacementsInStages(Object.fromEntries(placementsInStagesArray));
        });
    }, []);

    return (
        <>
            <BarChart title="Current placement stages" subtitle="Click to view placements." data={placementsInStages}/>
        </>
    );
}


export function ProviderSectorGraph({cohortData}:{cohortData: CohortData}) {
    const [placementsInSectors, setPlacementsInSectors] = useState<{[key: string]: {value: number, callback?:() => void}}>(Object.fromEntries(cohortData.workflow.map((stage) => [stage.name, {value: 0}])));

    const firebaseQuery = new FirebaseQuery();
    const user = useContext(UserContext);

    useEffect(() => {
        if (!cohortData) return;
        const constraints = [where("oId", "==", user.oId), where("cohort", "==", cohortData.id)];
        Promise.all(Object.keys(providerSectors).map(async (sector) => {
            const placementsAtStage = await firebaseQuery.getCount("placements", [...constraints, where("sector", "==", sector)]);
            return [sector, {value: placementsAtStage}];
        })).then((placementsInSectorArray) => {
            setPlacementsInSectors(Object.fromEntries(placementsInSectorArray));
        });
    }, []);

    return (
        <>
            <PieChart title="Placements in Sectors" data={placementsInSectors}/>
        </>
    );
}
