import {useContext, useEffect, useState} from "react";
import {OrganisationContext, UserContext} from "../../App";
import {CohortData, EmailTemplate, InstituteData, OrganisationAddress, UserGroupData, getAccess, getOrganisation} from "placementt-core";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import SideBarNav, {SideNavBarItems} from "../../Components/Navigation/SideNavBar";
import {Add, Approval, BusinessCenter, Dashboard, LibraryBooks, Map, PeopleAlt, Person, School} from "@mui/icons-material";
import StaffHome from "./Staff/Home";
import Cohorts from "./Staff/Cohorts/Cohorts";
import Profile from "../Shared/Profile";
import StudentHome from "./Students/Home";
import FormPage from "../Shared/FormPage";
import UserGroupForm from "./Staff/Forms/UserGroupForm";
import ProposePlacement from "../Shared/ProposePlacement";
import ViewPlacement from "../Shared/ViewPlacement";
import PlacementsNew from "./Staff/Placements";
import {documentId, where} from "firebase/firestore";
import UserProfile from "../Shared/UserProfile";
import Guidance from "../Shared/Guidance";
import PlacementMap from "../Shared/PlacementMap";
import CustomFormPage from "../../Components/CustomFormPage";
import Organisation from "./Staff/Organisation";
import CustomFormBuilder from "../../Components/CustomFormBuilder";
import StudentApplications from "../Shared/StudentApplications";
import Admissions from "./Staff/Admissions";


export default function Institutes() {
    const user = useContext(UserContext);
    const [institute, setInstitute] = useState<InstituteData>(); // {}
    const [userGroups, setUserGroups] = useState<{[key:string]: UserGroupData}>(); // {}
    const [forms, setForms] = useState<{[key:string]: unknown}>(); // []
    const [cohorts, setCohorts] = useState<{[key:string]: CohortData}>(); // []
    const [addresses, setAddresses] = useState<{[key:string]: OrganisationAddress}>(); // []
    const [emailTemplates, setEmailTemplates] = useState<{[key:string]: EmailTemplate}>(); // []

    const [applications, setApplications] = useState<number>();

    const firebaseQuery = new FirebaseQuery();

    useEffect(() => {
        getOrganisation(user, (data) => setInstitute(data as InstituteData));
        firebaseQuery.collectionSnapshot(setUserGroups, "userGroups", [where("oId", "==", user.oId), where("product", "==", user.product)]);
        firebaseQuery.collectionSnapshot(setForms, "forms", [where("oId", "==", user.oId), where("product", "==", user.product)]);
        if (user.userGroup === "admin" || (user.viewCohorts !== "none" && user.viewAddresses !== "none") || user.userType === "Students") {
            const cohortConstraints = [where("oId", "==", user.oId)];
            if (user.userGroup !== "admin" && user.userType === "Staff") {
                if (user.viewAddresses !== "all" && user.viewCohorts !== "some") {
                    cohortConstraints.push(where("addressId", "in", user.visibleAddresses));
                }
                if (user.viewCohorts !== "all") {
                    cohortConstraints.push(where(documentId(), "in", user.visibleCohorts));
                }
            }
            firebaseQuery.collectionSnapshot(setCohorts, "cohorts", cohortConstraints);
        } else {
            setCohorts({});
        }
        if (user.userGroup === "admin" || (user.userType === "Staff" && user.viewAddresses !== "none")) {
            firebaseQuery.collectionSnapshot(setAddresses, "addresses", [where("product", "==", user.product), where("oId", "==", user.oId), ...((user.viewAddresses === "all" || user.userGroup === "admin") ? [] : [where(documentId(), "in", user.visibleAddresses || [])])]);
        } else {
            firebaseQuery.collectionSnapshot(setAddresses, "addresses", [where("product", "==", user.product), where("oId", "==", user.oId), where("default", "==", true)]);
        }

        if (user.userType === "Staff") {
            firebaseQuery.collectionSnapshot(setEmailTemplates, "emailTemplates", [where("product", "==", user.product), where("oId", "==", user.oId)]);
        }

        if (user.userType === "Students") {
            firebaseQuery.getCount("applications", [where("uid", "==", user.id)]).then(setApplications);
        }
    }, []);

    const {editStaffGroups, editForms} = getAccess(user, "editStaffGroups", "editForms");

    const items:SideNavBarItems = user.userType === "Staff" ? [
        {
            label: "Home",
            route: "home",
            icon: <Dashboard/>,
            element: <StaffHome/>,
        },
        {
            label: "Cohorts",
            route: "cohorts",
            icon: <PeopleAlt/>,
            element: <Cohorts/>,
        },
        {
            label: "Placements",
            route: "placements",
            icon: <BusinessCenter/>,
            element: <PlacementsNew/>,
        },
        {
            label: "Guidance",
            route: "guidance",
            icon: <LibraryBooks/>,
            element: <Guidance/>,
        },
        {
            label: "Organisation",
            route: "organisation",
            icon: <School/>,
            element: <Organisation/>,
        },
        institute?.addOnPackages?.includes("admissions") && {
            label: "Admissions",
            route: "admissions",
            icon: <Approval/>,
            element: <Admissions/>,
        },
        {
            route: "userGroups",
            element: <FormPage title='Add user group' path='userGroups' editable={editStaffGroups}><UserGroupForm/></FormPage>,
        },
        {
            route: "userGroups/:id",
            element: <FormPage title='Edit user group' path='userGroups' editable={editStaffGroups}><UserGroupForm/></FormPage>,
        },
        {
            route: "form",
            element: <CustomFormPage/>,
        },
        {
            route: "organisation/resources/f",
            element: <FormPage title='Create custom form' path='forms' editable={editForms}><CustomFormBuilder/></FormPage>,
        },
        {
            route: "organisation/resources/f/:id",
            element: <FormPage title='Edit custom form' path='forms' editable={editForms}><CustomFormBuilder/></FormPage>,
        },
        {
            route: "cohorts/placements/:pid",
            element: <ViewPlacement/>,
        },
        {
            route: "users/:id",
            element: <UserProfile/>,
        },
        {
            route: "create",
            element: <ProposePlacement/>,
        },
        {
            route: "create/:uid",
            element: <ProposePlacement/>,
        },
    ].filter((i) => i) as SideNavBarItems : [
        {
            label: "Profile",
            route: "profile",
            icon: <Person/>,
            element: <Profile/>,
        },
        {
            label: "Home",
            route: "home",
            icon: <Dashboard/>,
            element: <StudentHome/>,
        },
        {
            label: institute?.studentsGuidance && Object.keys(institute.studentsGuidance).length ? "Guidance" : undefined,
            route: "guidance",
            icon: <LibraryBooks/>,
            element: <Guidance/>,
        },
        {
            label: applications ? "Applications" : undefined,
            route: "applications",
            icon: <Approval/>,
            element: <StudentApplications/>,
        },
        {
            label: "Search",
            route: "search",
            icon: <Map/>,
            element: <PlacementMap fullscreen={false} center={institute?.geoHash} title="Search placements" type="internal"/>,
        },
        {
            label: "Create",
            route: "create",
            icon: <Add/>,
            element: <ProposePlacement/>,
        },
        {
            route: "placements/:pid",
            element: <ViewPlacement/>,
        },
        {
            route: "form",
            element: <CustomFormPage/>,
        }];

    if (!institute || !userGroups || !forms || !cohorts) {
        return null;
    }
    console.log("Render Institutes");

    return (
        <OrganisationContext.Provider value={{details: institute, addresses: addresses, userGroups: userGroups, forms: forms, cohorts: cohorts, emailTemplates: emailTemplates}}>
            <SideBarNav items={items}/>
        </OrganisationContext.Provider>
    );
}
