import {INSTITUTE_COLOUR} from "placementt-core";
import InfoPageHeader from "../../Components/Public/InfoPageHeader";
import GatsbyBenchmarks from "placementt-core/lib/images/GatsbyBenchmarks";
import {List, ListItem, ListItemIcon, ListItemText, Typography, Box, Button, Stack} from "@mui/material";
import TwoColumnSection from "../../Components/Public/TwoColumnSection";
import ThreeColumnSection from "../../Components/Public/ThreeColumnSection/ThreeColumnSection";
import IconGridList from "../../Components/Public/IconGridList";
import {AccountTreeRounded, Analytics, AnalyticsRounded, AssignmentTurnedInRounded, BadgeRounded, CalendarMonth, CheckCircleRounded, DocumentScannerRounded, GroupsRounded, LightbulbRounded, PeopleRounded, RocketLaunch, SchoolRounded, ShieldRounded, SmsRounded} from "@mui/icons-material";
import CardSection from "../../Components/Public/CardSection";
import PublicTemplate from "../../Components/Public/PublicTemplate";
import Page from "../../Components/Page";
import EpsomEwell from "../../Images/TestimonialsLogos/EpsomEwell.webp";
import HallCrossAcademy from "../../Images/TestimonialsLogos/HallCrossAcademy.webp";
import KestevenGrantham from "../../Images/TestimonialsLogos/KestevenGrantham.webp";
import {useNavigate} from "react-router-dom";
import InstitutePriceCalculator from "../../Components/PriceCalculator";
import BillingTable from "../../Components/BillingTable";


export default function InstituteInfo() {
    const navigate = useNavigate();
    return (
        <Page
            metaTitle="Placementt | Educator"
            metaDesc="A platform for schools, trusts
            and universities to seamlessly manage work experience. Manage
            students and applicant work experience through a customisable workflow, track progress and communicate easily with your students."
        >
            <InfoPageHeader
                title="Placementt - Educator"
                subtext="Manage your entire placement programme seamlessly. Whether you are a secondary school, multi-academy trust or university, Placementt will support you with a suite of robust, hands-free tools."
                background={INSTITUTE_COLOUR}
                image={<GatsbyBenchmarks/>}
                scaleBackground
            />
            <PublicTemplate>
                <Box display="flex" justifyContent="center" alignItems="center">
                    <IconGridList
                        title="Core Features to Streamline Your Placements, from Secondary School to University"
                        titleColour={INSTITUTE_COLOUR}
                        items={[
                            {
                                title: "Customisable placement workflow",
                                icon: <AccountTreeRounded/>,
                            },
                            {
                                title: "Comprehensive user analytics",
                                icon: <Analytics/>,
                            },
                            {
                                title: "Robust communication tools",
                                icon: <SmsRounded/>,
                            },
                            {
                                title: "Simple, secure user management",
                                icon: <PeopleRounded/>,
                            },
                        ]}
                    />
                </Box>
                <TwoColumnSection
                    title="Bring out the best in your students"
                    titleColor={INSTITUTE_COLOUR}
                    sectionOne="Our platform doesn't just reduce your administrative workload, it supports students directly by building up comprehensive profiles of work placements. This means that your students have access to placements that will maximise their career prospects."
                    sectionTwo="Placementt will provide you a strong foundation for your placement programme, allowing you to easily support students and maximise their development. "
                />
                <Testimonials color={INSTITUTE_COLOUR}/>
                <CardSection
                    title="Your Complete Placement Platform"
                    titleColour={INSTITUTE_COLOUR}
                    items={[
                        {
                            title: "For Schools",
                            primaryColor: INSTITUTE_COLOUR,
                            summary: <><Typography fontWeight={"bold"} color={INSTITUTE_COLOUR} display={"inline"}>Manage your work experience programme</Typography> with ease. Our placement management platform will reduce admin and give you more time to spend on your students.</>,
                            secondaryContent:
                                <List>
                                    <ListItem>
                                        <ListItemIcon><AccountTreeRounded/></ListItemIcon>
                                        <ListItemText primary="Customisable workflow with automated sign-off from employers, parents and students"/>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon><DocumentScannerRounded/></ListItemIcon>
                                        <ListItemText primary="Automatic insurance, DBS and risk assessment acquisition"/>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon><SmsRounded/></ListItemIcon>
                                        <ListItemText primary="Customisable daily logs and feedback from employers and students"/>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon><AnalyticsRounded/></ListItemIcon>
                                        <ListItemText primary="Basic placement analytics"/>
                                    </ListItem>
                                </List>,
                        },
                        {
                            title: "For Multi-Academy Trusts",
                            primaryColor: INSTITUTE_COLOUR,
                            summary: <><Typography fontWeight={"bold"} color={INSTITUTE_COLOUR} display={"inline"}>Streamline your schools</Typography> with standardised placement workflows and advanced analytics.</>,
                            secondaryContent:
                            <List>
                                <ListItem>
                                    <ListItemIcon><CheckCircleRounded/></ListItemIcon>
                                    <ListItemText primary="All school features"/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><LightbulbRounded/></ListItemIcon>
                                    <ListItemText primary="Centralised, standardised placement processing"/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><AssignmentTurnedInRounded/></ListItemIcon>
                                    <ListItemText primary="Manage all school cohorts in one place"/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><GroupsRounded/></ListItemIcon>
                                    <ListItemText primary="Advanced analytics and reporting"/>
                                </ListItem>
                            </List>,
                        },
                        {
                            title: "For Universities",
                            primaryColor: INSTITUTE_COLOUR,
                            summary: <><Typography fontWeight={"bold"} color={INSTITUTE_COLOUR} display={"inline"}>Develop exceptional young professionals</Typography> with an advanced, secure platform. Expose your students to vibrant opportunities in any sector.</>,
                            secondaryContent:
                            <List>
                                <ListItem>
                                    <ListItemIcon><CheckCircleRounded/></ListItemIcon>
                                    <ListItemText primary="All previous features"/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><CalendarMonth/></ListItemIcon>
                                    <ListItemText primary="Manage long-term placements"/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><SchoolRounded/></ListItemIcon>
                                    <ListItemText primary="Customisable skills tracking"/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><ShieldRounded/></ListItemIcon>
                                    <ListItemText primary="Automate organising placement visits"/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><BadgeRounded/></ListItemIcon>
                                    <ListItemText primary="Placement assessments for students before, during and after placements."/>
                                </ListItem>
                            </List>,
                        },
                    ]}
                />
                <InstitutePriceCalculator/>
                <TwoColumnSection
                    title="Join a platform that strives to bring the best out of your students"
                    titleColor={INSTITUTE_COLOUR}
                    sectionOne="Since we started, our primary focus has been on building brighter futures for young people, to level the playing field across all students. Join us, as we strive to build the most comprehensive placement support network in the UK."
                    sectionTwo={
                        <Stack alignItems={"center"} justifyContent={"center"}>
                            <Button color="institutes" sx={{fontSize: "1.5em", padding: "0 16px"}} endIcon={<RocketLaunch fontSize="large"/>} onClick={() => navigate("/register")}>Join now</Button>
                            <Typography sx={{opacity: 0.7}}>(No strings attached)</Typography>
                        </Stack>}
                />
                <BillingTable product="institutes"/>
            </PublicTemplate>
        </Page>
    );
}


export function Testimonials({color}:{color: string}) {
    return (
        <ThreeColumnSection
            title="Our Testimonials"
            titleColor={color}
            sectionOne={{
                color: "#8b0000",
                image: HallCrossAcademy,
                imageTitle: "Hall Cross Academy",
                text: <Box>
                    <Typography>
                    "The platform has really made communication between the placement, students and school so much more efficient, reducing time spent on administration. We look forward to continuing to work with Placementt in the future to further develop our work experience programme."
                    </Typography>
                    <Typography mt={2} textAlign={"end"} color={"#8b0000"} fontWeight={"bold"}>Mrs D Stokes</Typography>
                    <Typography textAlign={"end"} color={"#8b0000"} fontWeight={"bold"}>Sixth Form Year Manager</Typography>
                </Box>,
            }}
            sectionTwo={{
                color: "#006400",
                image: EpsomEwell,
                imageTitle: "Epsom and Ewell High School",
                text: <Box>
                    <Typography>
                    "Placementt… have been thorough in their approach to making the journey for recording work experience as smooth as possible. They have been on hand to answer any questions… or adapt the process to make things easier for us. The best thing about this product is that the culture of the team behind it is all about making things as efficient as possible for us as users; we will certainly be working with them in the future!"
                    </Typography>
                    <Typography mt={2} textAlign={"end"} color={"#006400"} fontWeight={"bold"}>Miss H Cobbold</Typography>
                    <Typography textAlign={"end"} color={"#006400"} fontWeight={"bold"}>Assistant Headteacher</Typography>
                </Box>,
            }}
            sectionThree={{
                color: "#0080FE",
                image: KestevenGrantham,
                imageTitle: "Kesteven and Grantham Girls' School",
                text: <Box>
                    <Typography>
                    “Placementt has really simplified and streamlined the administration process related to work experience for us, so is an invaluable tool. We are also assured that it will help us develop a comprehensive database of employers who can accommodate our students in future. Most impressive was how reactive the team at Placementt were to our queries.”
                    </Typography>
                    <Typography mt={2} textAlign={"end"} color={"#0080FE"} fontWeight={"bold"}>Assistant Headteacher</Typography>
                </Box>,
            }}
        />
    );
}
