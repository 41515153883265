import {Edit} from "@mui/icons-material";
import {Alert, Autocomplete, Box, Button, Card, CardContent, CardHeader, Checkbox, Collapse, Divider, FormControlLabel, Grid, IconButton, Link, MenuItem, Stack, Table, TableCell, TableRow, TextField, Typography} from "@mui/material";
import {AnalyticsItem, CohortData, OrganisationAddress, PRIMARY_COLOUR, PlacementListing, StudentPlacementData, UserData, camelCaseToNormal, capitalise, capitaliseWords, executeCallable, getPlacementDateArray, searchUsers, useProposePlacementRenderer} from "placementt-core";
import {ReactNode, useContext, useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {OrganisationContext, UserContext} from "../../App";
import DeletePopup from "../../Components/DeletePopup";
import DateRangePicker from "../../Components/FormComponents/DateRangePicker";
import Dropdown from "../../Components/FormComponents/Dropdown";
import InputGroup from "../../Components/FormComponents/InputGroup";
import {Popup} from "../../Components/Popup";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import {InfoTableCell} from "../../Util/styledComponents";
import FadeInBox from "../../Components/FadeInBox";
import Page from "../../Components/Page";
import Form from "../../Components/Form";
import {LoadingButton} from "../../Components/LoadingButton";
import {where} from "firebase/firestore";
import DateRangePickerNew from "../../Components/FormComponents/DateRangePickerNew";

export default function ProposePlacement({placement}:{placement?:StudentPlacementData}) {
    const [delPopupActive, setDelPopupActive] = useState(false);
    const [error, setError] = useState<string>();
    const [providerConsent, setProviderConsent] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();
    const user = useContext(UserContext) as UserData;
    const orgContext = useContext(OrganisationContext);
    const firebaseQuery = new FirebaseQuery();

    if (location?.state?.placement) {
        placement = location?.state?.placement;
        console.log("PLCCAEM", placement);
    }

    const {
        stage,
        student,
        disabled,
        formData,
        complete,
        cohort,
        setStage,
        proposePlacement,
        deletePlacement,
        setStudent,
        submitSection} = useProposePlacementRenderer({...{user, orgContext, placement}});

    const {uid}:{uid?: string} = useParams();
    if (uid) {
        firebaseQuery.getDocData(["users", uid]).then((userDoc) => {
            const studentDoc = userDoc as UserData;
            if (studentDoc.userType !== "Students" || studentDoc.oId !== user.oId || studentDoc.product !== user.product) {
                setError("User is not in your organisation");
                return;
            }
            if (user.status !== "active") {
                setError("Student must be active to have a placement assigned to them.");
                return;
            }
            setStudent(studentDoc);
        }).catch(() => {
            setError("Cannot find user.");
        });
    }

    const submitParentEmail = async (e: {[key: string]: unknown}) => {
        if (!student?.id) {
            return {severity: "Error", message: "No associated student."};
        }
        await firebaseQuery.update(["users", student?.id], {["details.parentEmail"]: e.parentEmail as string});
    };

    const today = new Date();
    const startDateBeforeToday = formData?.startDate ? new Date(formData?.startDate) < today : undefined;

    const requiresParentEmail = Object.values(cohort?.workflow || {}).filter((stage) => stage.userType === "Parent").length > 0;
    const hasParentEmail = user.details.parentEmail !== "null";
    console.log(requiresParentEmail, hasParentEmail);


    if (user.product === "providers") return <ProviderProposePlacement/>;


    if (requiresParentEmail && !hasParentEmail) {
        return (
            <Page
                title="New placement"
                metaTitle="Placementt | New placement"
                metaDesc="Placementt - Add a new placement."
                fullHeight>
                <FadeInBox card visible cardTitle={"Before we start"}>
                    <Typography>Your school has requested you to input a parental email that will be contacted as part of processing your placement.</Typography>
                    <Stack spacing={2} mt={4}>
                        <Form onSubmit={submitParentEmail}>
                            <InputGroup required label={"Parent email address"} type="email" name={"parentEmail"}/>
                            <FormControlLabel required control={<Checkbox/>} label="I have parental consent to share this email." />
                        </Form>
                    </Stack>
                </FadeInBox>
            </Page>);
    }

    return (
        <Page
            title="New placement"
            metaTitle="Placementt | New placement"
            metaDesc="Placementt - Add a new placement."
            fullHeight>
            <Box>
                <FadeInBox card cardTitle={"Placement dates"} visible={stage === "dates"} key={"placement-form"}>
                    {disabled ? <Alert severity="info">Submissions are not allowed at this time.</Alert> :
                        <Form submitText={"Continue"} functionType="sync" onSubmit={(e) => submitSection("dates", e)}>
                            {user.userType === "Staff" && user.product === "institutes" && <>
                                <UserSearch userType="Students" defaultUid={uid} label={"Student"} onUserChange={(e) => {
                                    setStudent(e);
                                    setError(undefined);
                                }} />
                                <Collapse in={Boolean(error)}>
                                    <Alert severity="error">{error}</Alert>
                                </Collapse></>}
                            {formData?.placementId &&
                            <Collapse in>
                                <Alert severity="info">Placement at {formData.name} imported from Placementt map</Alert>
                            </Collapse>}
                            <Grid container>
                                {student?.analytics &&
                            <Grid item xs={12}>
                                <InputGroup required type={"number"} value={formData?.units} label={capitalise(student.units)} name={"units"} min={0}/>
                            </Grid>}
                                <Grid item xs={12}>
                                    <DateRangePicker name={"date"}
                                        required
                                        start={formData?.startDate}
                                        end={formData?.endDate}
                                        startMin={user.userType === "Students" ? cohort?.startPlacements : undefined}
                                        startMax={user.userType === "Students" ? cohort?.endPlacements : undefined}
                                        endMin={user.userType === "Students" ? cohort?.startPlacements : undefined}
                                        endMax={user.userType === "Students" ? cohort?.endPlacements : undefined}
                                    />
                                </Grid>
                                {student?.analytics && <AnalyticsSelector user={student} name="personalAnalytics"/>}
                            </Grid>
                        </Form>}
                </FadeInBox>
                <FadeInBox card cardTitle={"Provider details"} visible={stage === "provider"} key={"provider-form"}>
                    <Form disabled={disabled} functionType="sync" submitText={"Continue"} onSubmit={(e) => submitSection("provider", e)} onBackPress={() => setStage("dates")}>
                        <Grid container>
                            <Grid item xs={12}>
                                <InputGroup required name='name' label='Provider / Business name' value={formData?.name}/>
                            </Grid>
                            {startDateBeforeToday && <Grid item xs={12}>
                                <Typography color={"primary"} fontWeight={"bold"}>Optional</Typography>
                            </Grid>}
                            <Grid item xs={12}>
                                <InputGroup required={!startDateBeforeToday} name='providerEmail' type='email' label='Provider email' value={formData?.providerEmail}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <InputGroup required={!startDateBeforeToday} name='contactForename' label='Contact forename' value={formData?.contactForename}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <InputGroup required={!startDateBeforeToday} name='contactSurname' label='Contact surname' value={formData?.contactSurname}/>
                            </Grid>
                            <Grid item xs={12}>
                                <InputGroup required name='title' label='Job title' value={formData?.title}/>
                            </Grid>
                            {!startDateBeforeToday && <Grid item xs={12}>
                                <Typography color={"primary"} fontWeight={"bold"}>Optional</Typography>
                            </Grid>}
                            <Grid item xs={12}>
                                <InputGroup name='providerPhone' type='tel' pattern="^[0-9]*$" label='Provider phone' value={formData?.providerPhone}/>
                            </Grid>
                        </Grid>
                    </Form>
                </FadeInBox>
                <FadeInBox card cardTitle={"Provider address"} visible={stage === "address"} key={"address-form"}>
                    <Form submitText={"Continue"} functionType="sync" onSubmit={(e) => submitSection("address", e)} onBackPress={() => setStage("provider")}>
                        <Grid container>
                            {startDateBeforeToday && <Grid item xs={12}>
                                <Typography color={"primary"} fontWeight={"bold"}>Optional</Typography>
                            </Grid>}
                            <Grid item xs={12} sm={6}>
                                <InputGroup required={!startDateBeforeToday} id="address-line1" label="Address line one" name="address-line1" value={formData?.["address-line1"]}/>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputGroup id="address-line2" label="Address line two" name="address-line2" value={formData?.["address-line2"]}/>
                            </Grid>
                            <Grid item xs={12}>
                                <InputGroup required={!startDateBeforeToday} id="locality" label="City" name="locality" value={formData?.locality}/>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <InputGroup required={!startDateBeforeToday} id="postcode" label="Postcode" name="postal_code" value={formData?.postal_code}/>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <Dropdown required={!startDateBeforeToday} id="country" label="Country" name="country" value={formData?.country || "unitedKingdom"}/>
                            </Grid>
                        </Grid>
                    </Form>
                </FadeInBox>
                <FadeInBox visible={stage === "review"} key={"review-form"}>
                    <Stack sx={{width: "100%"}}>
                        <Card>
                            <CardHeader title={"Overview"}/>
                            <CardContent>
                                {formData?.placementId &&
                                <Collapse in>
                                    <Alert severity="info">Placement at {formData.name} imported from Placementt map</Alert>
                                </Collapse>}
                                <Table size="small">
                                    <TableRow>
                                        <InfoTableCell>Provider / Business name</InfoTableCell>
                                        <InfoTableCell>{formData?.name}</InfoTableCell>
                                        {Boolean(placement?.placementId) || <InfoTableCell><IconButton onClick={() => setStage("provider")}><Edit/></IconButton></InfoTableCell>}
                                    </TableRow>
                                    {formData?.providerEmail && <TableRow>
                                        <InfoTableCell>Provider email</InfoTableCell>
                                        <InfoTableCell>{formData?.providerEmail}</InfoTableCell>
                                    </TableRow>}
                                    <TableRow>
                                        <InfoTableCell>Job title</InfoTableCell>
                                        <InfoTableCell>{formData?.title}</InfoTableCell>
                                    </TableRow>
                                    {formData?.contactForename && <TableRow>
                                        <InfoTableCell>Contact name</InfoTableCell>
                                        <InfoTableCell>{`${formData?.contactForename} ${formData?.contactSurname}`}</InfoTableCell>
                                    </TableRow>}
                                    <TableRow>
                                        <TableCell colSpan={3}></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <InfoTableCell>Address</InfoTableCell>
                                        <InfoTableCell>{formData?.["address-line1"] ? `${formData?.["address-line1"]} - ${formData?.["address-line2"]}` : <Typography color={"grey"}>Not provided</Typography>}</InfoTableCell>
                                        {Boolean(placement?.placementId) || <InfoTableCell><IconButton onClick={() => setStage("address")}><Edit/></IconButton></InfoTableCell>}
                                    </TableRow>
                                    {formData?.postal_code && <TableRow>
                                        <InfoTableCell></InfoTableCell>
                                        <InfoTableCell>{`${formData?.locality} - ${formData?.postal_code} - ${capitaliseWords(camelCaseToNormal(formData?.country))}`}</InfoTableCell>
                                    </TableRow>}
                                    <TableRow>
                                        <TableCell colSpan={3}></TableCell>
                                    </TableRow>
                                    {formData?.startDate && <TableRow>
                                        <InfoTableCell>Dates</InfoTableCell>
                                        <InfoTableCell>{`${formData?.startDate} - ${formData?.endDate}`}</InfoTableCell>
                                        {Boolean(placement?.placementId) || <InfoTableCell><IconButton onClick={() => setStage("dates")}><Edit/></IconButton></InfoTableCell>}
                                    </TableRow>}
                                </Table>
                            </CardContent>
                        </Card>
                        <FormControlLabel label={"I confirm I have contacted this business directly, and received confirmation of my place on this placement on the dates specified."} control={<Checkbox onChange={(e) => setProviderConsent(e.target.checked)}/>}/>
                        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} flexWrap={"wrap"}>
                            <Link onClick={() => setStage("provider")}>Back</Link>
                            <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                <LoadingButton disabled={!providerConsent}>
                                    {user.userType !== "Staff" && <Button onClick={() => proposePlacement(true).then((complete) => complete || navigate("/institutes/home"))} variant="outlined">Save as draft</Button>}
                                    <Button onClick={() => proposePlacement().then((complete) => complete || navigate("/institutes/home"))} variant="contained">Create</Button>
                                    {placement && placement.draft && <Button disabled={false} color="error" sx={{margin: "auto"}} onClick={() => setDelPopupActive(true)}>Delete</Button>}
                                </LoadingButton>

                            </Stack>
                        </Stack>
                    </Stack>
                </FadeInBox>
            </Box>
            {placement &&
                <DeletePopup
                    open={delPopupActive}
                    onClose={() => {
                        setDelPopupActive(false);
                    }}
                    title={"placement"}
                    onDelete={() => deletePlacement(placement?.id || "").then(() => navigate("/institutes/home"))}/>}
            <Popup open={complete} onClose={() => navigate("/institutes/home")} cardSx={{maxWidth: "500px"}} noCloseButton>
                <Stack spacing={4} textAlign={"center"} alignItems={"center"}>
                    <Typography variant='h4'>Placement submitted</Typography>
                    <Typography variant='subtitle1' textAlign={"center"}>We'll let you know when your placement status changes.</Typography>
                    <Button onClick={() => navigate("/institutes/home")}>Home</Button>
                </Stack>
            </Popup>
        </Page>
    );
}

export function UserSearch({onUserChange, userType, label, required, defaultUid, name}:{onUserChange?:(e?: UserData) => void, userType: "Staff"|"Students", label: string, required?: boolean, defaultUid?: string, name?: string}) {
    const [query, setQuery] = useState("");
    const [results, setResults] = useState<Array<{label:string, user:UserData}>>([]);
    const [selectedUser, setSelectedUser] = useState<{label: string, user: UserData}|null>();

    const user = useContext(UserContext);
    const firebaseQuery = new FirebaseQuery();

    useEffect(() => {
        console.log("UID", defaultUid);
        if (!defaultUid) return;

        firebaseQuery.getDocData(["users", defaultUid]).then((u) => setSelectedUser({label: `${u.details.forename} ${u.details.surname} - ${u.email}`, user: u as UserData}));
    }, [defaultUid]);

    useEffect(() => {
        searchUsers(userType, query, user).then((users) => {
            setResults(Object.entries(users).reduce((acc, [, user]) => {
                acc.length < 5 && acc.push({label: `${user.details.forename} ${user.details.surname} - ${user.email}`, user: user}); return acc;
            }, [] as Array<{label:string, user:UserData}>));
        });
    }, [query]);

    useEffect(() => {
        if (!selectedUser || !onUserChange) return;
        onUserChange && onUserChange(selectedUser.user);
    }, [selectedUser]);

    if (defaultUid && selectedUser === undefined) return null;

    return (
        <>
            <Autocomplete
                disablePortal
                id="userSelector"
                value={selectedUser}
                onChange={(e, value) => {
                    setSelectedUser(value);
                    setResults([]);
                }}
                options={results}
                renderInput={(params) => <TextField onChange={(e) => setQuery(e.target.value)} {...params} {...{label, required}} placeholder="Enter name"/>}
            />
            <input hidden {...{name, required}} value={selectedUser?.user.id}/>
        </>
    );
}

function AnalyticsSelector({user, name, disabled, value}:{user: UserData, name: string, disabled?: boolean, value?: string[]}) {
    const [analyticsSelection, setAnalyticsSelection] = useState<string[]>();

    const analytics = user.analytics;

    if (!analytics) return null;

    useEffect(() => {
        setAnalyticsSelection(value);
    }, [value]);

    const renderSubtypeSelector = (item: AnalyticsItem, depth: number):ReactNode => {
        return (
            <>
                <Grid item xs={12}>
                    <Dropdown id="subtype-dropdown" label={"Subtype"} {...{disabled}} value={analyticsSelection?.[depth] || ""} onChange={(e) => setAnalyticsSelection((a) => [...(a ? a.slice(0, depth) : []), e.target.value])}>
                        {item.subtypes && Object.entries(item.subtypes).map(([id, subItem]) => <MenuItem value={id}>{subItem.name}</MenuItem>)}
                    </Dropdown>
                </Grid>
                {analyticsSelection && analyticsSelection[depth] && item.subtypes && Object.keys(item?.subtypes[analyticsSelection[depth]]?.subtypes || {}).length > 0 && renderSubtypeSelector(item.subtypes[analyticsSelection[depth]], depth+1)}
            </>
        );
    };

    return (
        <>
            <input hidden readOnly value={JSON.stringify(analyticsSelection)} name={name}/>
            {analytics.subtypes &&
            <><Grid item xs={12}>
                <Dropdown id="placement-type-dropdown" label={"Placement type"} {...{disabled}} value={analyticsSelection?.[0] || ""} onChange={(e) => setAnalyticsSelection([e.target.value])}>
                    {Object.entries(analytics?.subtypes).map(([id, item]) => <MenuItem value={id}>{item.name}</MenuItem>)}
                </Dropdown>
            </Grid>
            {(analyticsSelection && Object.keys(analytics?.subtypes[analyticsSelection[0]]?.subtypes || {}).length > 0) && renderSubtypeSelector(analytics.subtypes[analyticsSelection[0]], 1)}
            </>}
        </>
    );
}


type Params = {
    open: boolean,
    initialData: StudentPlacementData,
    onClose: ()=>void,
    cohort?: CohortData,
    viewingUser: UserData,
    student: UserData
}

export function EditPlacementPopup({open, initialData, onClose, cohort, viewingUser, student}:Params) {
    const firebaseQuery = new FirebaseQuery();

    const onSubmit = async (data:object) => {
        const uploadData = {...data, activeDates: getPlacementDateArray(data["startDate" as keyof typeof data], data["endDate" as keyof typeof data])};
        await firebaseQuery.update(["placements", initialData.id], uploadData);
        executeCallable("userManagement-uploadAnalytics", {});
        onClose();
    };

    return (
        <Popup {...{open, onClose}} title={"Edit placement"} cardSx={{width: "500px", maxWidth: "100%"}}>
            <Form onSubmit={onSubmit}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h6">Provider information</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <InputGroup required name='name' label='Provider name' value={initialData.name}/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InputGroup required name='contactForename' label='Contact forename' value={initialData.contactForename}/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InputGroup required name='contactSurname' label='Contact surname' value={initialData.contactSurname}/>
                    </Grid>
                    <Grid item xs={12}>
                        <InputGroup required name='providerEmail' type='email' label='Provider email' value={initialData.providerEmail}/>
                    </Grid>
                    <Grid item xs={12}>
                        <InputGroup name='providerPhone' type='number' label='Provider phone' value={initialData.providerPhone}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">Address</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputGroup required id="address-line1" label="Address line one" name="address-line1" value={initialData["address-line1"]}/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputGroup id="address-line2" label="Address line two" name="address-line2" value={initialData["address-line2"]}/>
                    </Grid>
                    <Grid item xs={12}>
                        <InputGroup required id="locality" label="City" name="locality" value={initialData.locality}/>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <InputGroup required id="postcode" label="Postcode" name="postal_code" value={initialData.postal_code}/>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Dropdown required id="country" label="Country" name="country" value={initialData.country}/>
                    </Grid>
                    <Grid item xs={12}>
                        <InputGroup required name='title' label='Placement title' value={initialData.title}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">Placement</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <DateRangePicker name={"date"}
                            startMin={viewingUser.userType === "Students" ? cohort?.startPlacements : undefined}
                            startMax={viewingUser.userType === "Students" ? cohort?.endPlacements : undefined}
                            endMin={viewingUser.userType === "Students" ? cohort?.startPlacements : undefined}
                            endMax={viewingUser.userType === "Students" ? cohort?.endPlacements : undefined}
                            start={initialData.startDate}
                            end={initialData.endDate}/>
                    </Grid>
                    {student?.analytics &&
                        <Grid item xs={12}>
                            <InputGroup required value={initialData.units} type={"number"} label={capitalise(student.units)} name={"units"} min={0}/>
                        </Grid>}
                    {student?.analytics && <AnalyticsSelector value={initialData.personalAnalytics} user={student} name="personalAnalytics"/>}
                </Grid>
            </Form>
        </Popup>
    );
}


function ProviderProposePlacement() {
    const user = useContext(UserContext);
    if (user.product !== "providers") throw new Error("Not a provider.");
    const navigate = useNavigate();

    const provider = useContext(OrganisationContext);

    // These are only used when providers are creating a student placement.
    const [selectedAddress, setSelectedAddress] = useState<string>();
    const [selectedListing, setSelectedListing] = useState<PlacementListing>();
    const [successPopup, setSuccessPopup] = useState<string|false>(false);
    const [studentRequestCopied, setStudentRequestCopied] = useState(false);

    const firebaseQuery = new FirebaseQuery();

    const addresses = provider.addresses as {[key: string]: OrganisationAddress&{listings: number}};

    type PlacementRequest = {
        studentForename: string,
        studentSurname: string,
        studentEmail: string,
        addressId: string,
        listingId: string,
        startDate: string,
        endDate: string
    }

    const addPlacementRequest = async (details: PlacementRequest) => {
        if (!selectedAddress) throw new Error("No address selected");
        if (!selectedListing) throw new Error("No listing selected");


        const responsibleUser = await firebaseQuery.getDocData(["users", selectedListing.primaryContactId]) as UserData;

        const proposePlacementData = {
            "placementId": selectedListing.id,
            ...details,
            "providerId": user.oId,
            "providerEmail": responsibleUser.email,
            "contactForename": responsibleUser.details.forename,
            "contactSurname": responsibleUser.details.surname,
            "name": provider.details?.name,
        };

        const res = await executeCallable("placement-propose", {data: proposePlacementData, createdByProvider: true});
        setSuccessPopup(res.data);
    };

    return (
        <Page
            title="New placement"
            metaTitle="Placementt | New placement"
            metaDesc="Placementt - Add a new placement."
            fullHeight>
            <Box>
                <FadeInBox card cardTitle={"Placement details"} visible key={"placement-form"}>
                    <Form submitText={"Create"} onSubmit={async (e) => addPlacementRequest(e as PlacementRequest)}>
                        <Typography variant="h6">Student details</Typography>
                        <Grid container>
                            <Grid item xs={12} md={6}>
                                <InputGroup label={"Forename"} required placeholder={"Student forename"} name={"studentForename"}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <InputGroup label={"Surname"} required placeholder={"Student surname"} name={"studentSurname"}/>
                            </Grid>
                            <Grid item xs={12}>
                                <InputGroup label={"Email"} type={"email"} required placeholder={"Student email"} name={"studentEmail"}/>
                            </Grid>
                        </Grid>
                        <Divider/>
                        <Typography variant={"h6"}>Placement details</Typography>
                        <Dropdown required name={"addressId"} label={"Address"} onChange={(e) => setSelectedAddress(e.target.value)}>
                            <Typography p={2} sx={{opacity: 0.7}}>Only addresses with associated listings are shown</Typography>
                            <Divider/>
                            {Object.entries(addresses).filter(([, address]) => address.listings > 0).map(([addressId, address]) =>
                                <MenuItem value={addressId} divider>
                                    <Stack spacing={0}>
                                        <Typography><strong>{address.name}</strong> - {address["address-line1"]} | {address.postal_code.toUpperCase()}</Typography>
                                        <Typography variant={"subtitle2"} sx={{opacity: 0.7}}>Associated listings: {address.listings}</Typography>
                                    </Stack>
                                </MenuItem>)}
                        </Dropdown>
                        <Typography>Select the listing this placement is associated with</Typography>
                        <Dropdown
                            key={selectedAddress}
                            label="Placement listing"
                            required
                            onChange={(e) => setSelectedListing(JSON.parse(e.target.value) as PlacementListing)}
                            path={["placementListings", [where("providerId", "==", user.oId), where("status", "==", "listed"), where("addressId", "==", selectedAddress || "")]]}
                            formatMenuItems={(e) => <MenuItem value={JSON.stringify(e)}>{(e as PlacementListing).title}</MenuItem>}/>
                        <DateRangePickerNew required name={"date"}/>
                        <Typography variant={"subtitle2"} textAlign={"center"} sx={{opacity: 0.7}}>Once you have created this placement, we will contact the student and request that they accept the placement. Once accepted, you can share details, onboarding and feedback with them.</Typography>
                    </Form>
                </FadeInBox>
            </Box>
            <Popup open={Boolean(successPopup)} onClose={() => navigate("/providers/placementListings/students")} cardSx={{maxWidth: "500px"}} noCloseButton>
                <Stack spacing={4} textAlign={"center"} alignItems={"center"}>
                    <Typography variant='h4'>Placement submitted</Typography>
                    <Typography variant='subtitle1' textAlign={"center"}>We have contacted this student requesting they accept this placement. You can share the link with them at any time by going to their placement page or copying the link below.</Typography>
                    <Stack direction={"row"} spacing={0} alignItems={"center"} width={"100%"}>
                        <InputGroup sx={{color: `${PRIMARY_COLOUR} !important`, pointerEvents: "none"}} value={`https://placementt.co.uk/placementRequest/${successPopup}`}/>
                        <Button sx={{height: "40px !important", borderRadius: "0px 4px 4px 0px", marginLeft: "-10px", background: "white !important"}} onClick={() => {
                            navigator.clipboard.writeText(`https://placementt.co.uk/placementRequest/${successPopup}`);
                            setStudentRequestCopied(true);
                        }}>{studentRequestCopied ? "Coped!" : "Copy"}</Button>
                    </Stack>
                    <Button onClick={() => navigate(`/${user.product}/home`)}>Home</Button>
                </Stack>
            </Popup>
        </Page >
    );
}

